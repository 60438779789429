const cn = {
  toString: () => 'cn',
  chinchinois: {
    title: '「新新话」',
    btnAssist: '阅读辅助级别',
    btnRemember: '背诵新话',
    btnClear: '消除痕迹',
    researcher: '研究员X',
  },
  home: {
    title: '啊! 玩具',
    title0: '啊! ',
    title1: '玩具!',
  },
  qc: {
    btnCBGen: '从剪切板生成二维码',
    btnGen: '生成二维码',
  },
}
const en = {
  toString: () => 'en',
  chinchinois: {
    title: '"ChinChinois"',
    btnAssist: 'Reading Assist Level',
    btnRemember: 'Memorize',
    btnClear: 'Clear',
    researcher: 'Researcher X',
  },
  home: {
    title: 'Oh! Toys!',
    title0: 'Oh! ',
    title1: 'Toys!',
  },
  qc: {
    btnCBGen: 'Generate QR Code from clipboard',
    btnGen: 'Generate QR Code',
  },
}

export let lang = navigator.language.match(/zh-cn|zh-CN/) ? cn : en
export const isCN = lang.toString() === 'cn'

export function setLang(l) {
  lang = l === 'cn' ? cn : en
}
