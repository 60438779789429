<script>
  import { onMount } from 'svelte'
  import ICON from '../icons.svelte'
  import OaFooter from '../OaFooter.svelte'
  import html2canvas from 'html2canvas'
  import { clamp } from '../helpers.svelte'
  import { patterns } from './pattern'
  import ImgSaver from '../ImgSaver.svelte'

  let square
  const ifiOS =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
  const ifWX =
    navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
    'micromessenger'
  let isSaving = false

  let dataUrl
  let opts = {
    fontSize: 3, // unit: rem
    isItalic: true,
    isBold: true,
    isThrough: false,
    hasShadow: false,
    showPayment: false,
  }
  const bgs = [
    'purple-pink',
    'red-pink',
    'ocean-blue',
    'peach',
    'cyan',
    'orange',
    'wine',
    'pinkier',
    'oppo',
    'wawei',
    'purple-passion',
    'off-red',
    'grass',
    'neon-1',
    'neon-2',
    'neon-3',
    'neon-4',
    'neon-5',
  ]
  let bgClr = r(bgs[0])
  let nextBgClr = r(bgClr)

  let heightChangeStops = [] // at which char the height changed
  let lastH = 0
  let txt
  let tmpcanva = document.createElement('canvas')

  function r(clr) {
    const c = bgs[Math.floor(Math.random() * bgs.length)]
    return c == clr ? r(clr) : c
  }
  function onInput(evt) {
    let tmp = evt.target.value
    tmp = tmp.slice(0, 140)
    evt.target.value = tmp
    let newH = Math.min(evt.target.scrollHeight, window.innerWidth)

    // record H change stops
    if (newH !== lastH) {
      // console.log("new big", lastH, newH);
      if (lastH === 0) {
        lastH = newH
      } else {
        heightChangeStops.push([tmp.length, lastH])
        lastH = newH
        // console.log(heightChangeStops);
      }
    }
    // handle backspace
    if (evt.inputType === 'deleteContentBackward') {
      // console.log("back");
      if (heightChangeStops.length > 0) {
        // console.log(">>>");
        // restore height if length shorter than last stop
        heightChangeStops.map(([len, h]) => {
          if (tmp.length === len - 1 && newH > h) {
            newH = h
            // console.log("set h");
            // console.log(len, h, newH);
          }
        })
      }
    }
    // set new H for textarea & text
    evt.target.style.height = newH + 'px'
    // txt.style.height = newH + "px";
    tmp = tmp.replace(/\n/gi, '<br/>')
    txt.innerHTML = tmp
    // console.log(evt);
  }
</script>

<svelte:head>
  <title>方块日记</title>
  <style>
    @import url('https://fonts.loli.net/css?family=Gochi+Hand&display=swap');
    @import url('https://fonts.loli.net/css?family=Bungee+Outline&display=swap');
    @import url('https://fonts.loli.net/css?family=ZCOOL+QingKe+HuangYou&display=swap');
    @import url('https://fonts.loli.net/css?family=Liu+Jian+Mao+Cao&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Megrim&display=swap');
    ::-webkit-scrollbar {
      width: 0px; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
  </style>
</svelte:head>
<div class="root">
  <div class="square {bgClr}" bind:this={square}>
    <textarea
      spellcheck="false"
      placeholder="点击输入文字.."
      class="txt-input"
      class:italic={opts.isItalic}
      class:bold={opts.isBold}
      class:through={opts.isThrough}
      style="font-size:{opts.fontSize}rem;"
      on:input={onInput}
      type="text"
    />
    <span
      class="txt"
      bind:this={txt}
      class:italic={opts.isItalic}
      class:bold={opts.isBold}
      class:through={opts.isThrough}
      class:text-shadow={opts.hasShadow}
      style="font-size:{opts.fontSize}rem;"
      type="text"
    />
  </div>
  <div class="controls">
    <div>
      <button
        class="size"
        on:click={evt => {
          evt.preventDefault()
          opts.fontSize = clamp(opts.fontSize - 0.5, 0.5, 6)
        }}
      >
        A-
      </button>
      <button
        class="size"
        on:click={evt => {
          evt.preventDefault()
          opts.fontSize = clamp(opts.fontSize + 0.5, 0.5, 6)
        }}
      >
        A+
      </button>
      <button
        class:italic={opts.isItalic}
        on:click={evt => {
          evt.preventDefault()
          opts.isItalic = !opts.isItalic
        }}
      >
        i
      </button>
      <button
        class:bold={opts.isBold}
        on:click={evt => {
          evt.preventDefault()
          opts.isBold = !opts.isBold
        }}
      >
        B
      </button>
      <button
        class:through={opts.isThrough}
        on:click={evt => {
          evt.preventDefault()
          opts.isThrough = !opts.isThrough
        }}
      >
        T
      </button>
      <button
        class:text-shadow={opts.hasShadow}
        on:click={evt => {
          evt.preventDefault()
          opts.hasShadow = !opts.hasShadow
        }}
      >
        S
      </button>
    </div>

    <button
      on:click={() => {
        bgClr = nextBgClr
        nextBgClr = r(bgClr)
      }}
      class="clr-changer "
    >
      <span class={bgClr} />
      <span class={nextBgClr} />
    </button>
  </div>

  <button
    class="btn-save {bgClr}"
    on:click={() => {
      window.scrollTo(0, 0)
      html2canvas(square).then(canva => {
        tmpcanva.width = canva.width - 3
        tmpcanva.height = canva.height - 3
        tmpcanva.getContext('2d').drawImage(canva, -1, -1)
        dataUrl = tmpcanva.toDataURL('image/png', 1)
        isSaving = true
      })
    }}
  >
    <!-- <ICON name="save" stroke="white" /> -->
    生成卡片
  </button>
  <button
    class="btn-pay {bgClr}"
    on:click={() => {
      opts.showPayment = !opts.showPayment
      setTimeout(() => {
        window.scrollBy(0, 300)
      })
    }}
  >
    给作者打5毛钱
  </button>
  <div class="payments" class:hidden={!opts.showPayment}>
    <figure>
      <img src="assets/ali.jpeg" alt="ali" />
      <figcaption>Alipay</figcaption>
    </figure>
    <figure>
      <img src="assets/wx.jpeg" alt="wx" />
      <figcaption>Wechat</figcaption>
    </figure>
  </div>

  <div class="just-padding" />
  <OaFooter />
  <ImgSaver bind:isSaving {dataUrl} />
  <!-- <figure
    class="output-container"
    class:isSaving
    class:ios-output={ifiOS}
    on:click={evt => {
      if (evt.target.tagName !== 'IMG') {
        isSaving = false;
      }
    }}>
    <img bind:this={dataUrl} src="#" alt="" />
    <figcaption>
      长按图片保存
      <br />
      Long press image to save.
    </figcaption>
  </figure> -->
</div>

<style lang="scss">
  .root {
    background: #fafafa;
    --squareSize: min(100vw, 60vh);
    --patternFood: none;
    margin: auto;
    width: var(--squareSize);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: #333;
  }
  .text-shadow {
    text-shadow: 1px 1px 1px rgb(0, 0, 0), 2px 2px 1px rgb(0, 0, 0);
  }
  .italic {
    font-style: italic;
  }
  .bold {
    font-weight: bold;
  }
  .through {
    text-decoration: line-through;
  }
  ////////  ////////
  //// Themes ////////////
  ////////////  ////
  .neon-1 {
    color: lightyellow;
    text-shadow: 0 0 10px var(--color1), 0 0 20px var(--color2),
      0 0 40px var(--color3), 0 0 80px var(--color4);
    --color1: gold;
    --color2: firebrick;
    --color3: pink;
    --color4: red;

    font-family: 'Liu Jian Mao Cao', cursive;
    background: black;
  }
  .neon-2 {
    background: #000;
    text-shadow: 0 0 10px var(--color1), 0 0 20px var(--color2),
      0 0 40px var(--color3), 0 0 80px var(--color4);
    color: white;
    --color1: #ff00de;
    --color2: #aa22aa;
    --color3: #829;
    --color4: #736;
    font-family: 'ZCOOL QingKe HuangYou', cursive;
  }
  .neon-3 {
    text-shadow: 0 0 10px var(--color1), 0 0 20px var(--color2),
      0 0 40px var(--color3), 0 0 80px var(--color4);
    background: #000;
    color: azure;
    --color1: azure;
    --color2: aqua;
    --color3: dodgerblue;
    --color4: blue;
    font-family: 'Gochi Hand', cursive;
  }
  .neon-4 {
    background: #000;
    text-shadow: 0 0 10px var(--color1), 0 0 20px var(--color2),
      0 0 40px var(--color3), 0 0 80px var(--color4);
    color: lightpink;
    --color1: pink;
    --color2: orangered;
    --color3: red;
    --color4: magenta;
    // font-family: Bad Script;
    font-family: 'Bungee Outline', cursive;
  }
  .neon-5 {
    background: #000;
    text-shadow: 0 0 10px var(--color1), 0 0 20px var(--color2),
      0 0 40px var(--color3), 0 0 80px var(--color4);
    color: rgb(182, 255, 192);
    --color1: rgb(114, 221, 123);
    --color2: rgb(60, 192, 67);
    --color3: rgb(61, 236, 99);
    --color4: rgb(70, 124, 73);
    // font-family: Bad Script;
    font-family: 'Megrim', cursive;
  }
  .purple-passion {
    background-color: #575890;
  }
  .grass {
    background: #88ac61;
  }
  .off-red {
    background: #d34d58;
  }
  .red-pink {
    background: rgb(255, 148, 131);
    background: linear-gradient(
      145deg,
      rgba(255, 148, 131, 1) 0%,
      rgba(125, 119, 255, 1) 100%
    );
  }
  .ocean-blue {
    background: rgb(52, 153, 255);
    background: linear-gradient(
      145deg,
      rgba(52, 153, 255, 1) 0%,
      rgba(58, 57, 133, 1) 100%
    );
  }
  .peach {
    background: rgb(255, 205, 165);
    background: linear-gradient(
      145deg,
      rgba(255, 205, 165, 1) 0%,
      rgba(238, 77, 95, 1) 100%
    );
  }
  .pinkier {
    background: rgb(255, 152, 151);
    background: linear-gradient(
      145deg,
      rgba(255, 152, 151, 1) 0%,
      rgba(246, 80, 160, 1) 100%
    );
  }
  .oppo {
    background: rgb(143, 192, 255);
    background: linear-gradient(
      145deg,
      rgba(143, 192, 255, 1) 0%,
      rgba(0, 186, 74, 1) 100%
    );
  }
  .wawei {
    background: rgb(110, 226, 245);
    background: linear-gradient(
      145deg,
      rgba(110, 226, 245, 1) 0%,
      rgba(246, 80, 160, 1) 100%
    );
  }
  .cyan {
    background: rgb(0, 255, 237);
    background: linear-gradient(
      145deg,
      rgba(0, 255, 237, 1) 0%,
      rgba(0, 184, 186, 1) 100%
    );
  }
  .orange {
    background: rgb(255, 166, 46);
    background: linear-gradient(
      145deg,
      rgba(255, 166, 46, 1) 0%,
      rgba(234, 77, 44, 1) 100%
    );
  }
  .wine {
    background: rgb(240, 11, 81);
    background: linear-gradient(
      145deg,
      rgba(240, 11, 81, 1) 0%,
      rgba(115, 0, 98, 1) 100%
    );
  }
  .purple-pink {
    background: rgb(255, 108, 171);
    background: linear-gradient(
      145deg,
      rgba(255, 108, 171, 1) 0%,
      rgba(115, 102, 255, 1) 100%
    );
  }
  .square {
    top: 0;
    position: sticky;
    z-index: 1111;
    width: var(--squareSize);
    height: var(--squareSize);
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    box-shadow: none;
  }
  .txt-input {
    position: absolute;
    resize: none;
    max-width: 100%;
    height: 100px;
    text-align: center;
    color: transparent;
    padding: 3% 5%;
    word-wrap: normal;
    background: none;
    border: none;
    outline: none;
    &::placeholder {
      color: #fafafa;
      font-size: 2rem;
    }
    margin: 0;
  }
  .txt {
    pointer-events: none;
    position: absolute;
    max-width: 100%;
    // height: 100px;
    text-align: center;
    color: white;
    padding: 3% 5%;
    word-wrap: normal;
    background: none;
    border: none;
    outline: none;
    margin: 0;
  }
  // .txt-input {
  //   width: 100%;
  //   height: 15%;
  //   outline: none;
  //   border: none;
  // }
  .controls {
    // width: 100%;
    z-index: 999;
    position: sticky;
    top: var(--squareSize);
    background: white;
    padding-left: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    div > button {
      // color: white;
      margin-right: 5px;
      width: 32px;
      height: 32px;
      background: none;
      border: none;
      outline: none;
    }
    .clr-changer {
      // position: absolute;
      // justify-self: flex-end;
      border: none;
      outline: none;
      margin-right: 16px;
      margin-top: 4px;
      border-radius: 20px;
      width: 56px;
      height: 26px;
      display: flex;
      // padding: 3px 5px;
      // box-shadow: 1px 2px 7px #3338;
      // overflow: hidden;
      position: relative;
      background: white;
      span {
        width: 22px;
        height: 22px;
        // width: 100%;
        // height: 100%;
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: -1px 2px 4px #3334;
        &:first-child {
          // z-index: 100;
          transform: translate(22px, 0px);
          // box-shadow: 0px 0px 5px #fff3;
        }
        &:last-child {
          width: 24px;
          height: 24px;
          // position: absolute;
          // left: 0;
          // top: 0;
          z-index: 22;
          transform: translate(34px, -1px);
        }
      }
      // top: 5px;
    }
  }
  .btn-save {
    width: 100%;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    color: white;
    margin-top: 35px;
    outline: none;
    border: none;
    border-radius: 0;
    // margin-top: 18px;
    // position: absolute;
    // bottom: 0;
  }
  .fk-hidden {
    visibility: hidden;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  // footer {
  //   // text-align: start;
  //   justify-content: center;
  //   padding: 0 12px;
  //   display: flex;
  //   height: var(--footerH);
  //   align-items: center;
  //   a {
  //     color: var(--clrLink);
  //     text-decoration: none;
  //     margin-left: 0.4rem;
  //   }
  // }
  .payments {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: min-content;
    justify-content: space-around;
    > * {
      width: 100px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
      }
      figcaption {
        text-align: center;
      }
    }
  }
  .hidden {
    display: none;
  }
  .save-hint {
    font-size: 0.8rem;
    width: 100%;
    text-align: center;
  }
  .btn-pay {
    margin-top: 80px;
    border-radius: 24px;
    color: white;
    border: none;
    outline: none;
    width: 40%;
    height: 48px;
    opacity: 0.5;
    font-weight: bold;
    margin: 15% 30% 0;
  }

  .output-container {
    z-index: 1112;
    padding: 0;
    margin: 0;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background: transparent;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.8s;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    pointer-events: none;
    img {
      pointer-events: none;
      object-fit: contain;
      border-radius: 15px;
      width: 70%;
      position: relative;
      margin-top: 10px;
      margin-bottom: 25%;
    }
    figcaption {
      margin-top: 10%;
      position: relative;
      color: white;
      text-align: center;
    }

    &.isSaving {
      background: #2229;
      pointer-events: visible;
      opacity: 1;
      img {
        pointer-events: visible;
      }
    }
  }
  .ios-output {
    background: rgba(34, 34, 34, 0.752) !important;
  }
  .just-padding {
    height: 30px;
    width: 100%;
  }
</style>
