<script>
  import {ifiOS, downloadImgURL} from './helpers.svelte'
  import {createEventDispatcher} from 'svelte'

  const dispatch = createEventDispatcher()

  export let dataUrl
  export let isSaving = false
</script>

<style lang="scss">
  .output-container {
    z-index: 1112;
    padding: 0;
    margin: 0;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background: transparent;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.8s;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    pointer-events: none;
    img {
      user-select: none;
      pointer-events: none;
      object-fit: contain;
      border-radius: 15px;
      width: min(70vw, 40vh);
      /* height: min-content; */
      margin: 10px 0 0;
      position: relative;
    }
    button {
      border-radius: 15px;
      background: #fafafa;
      border: none;
    }
    figcaption {
      position: relative;
      color: white;
      text-align: center;
    }

    &.isSaving {
      background: #2229;
      pointer-events: visible;
      opacity: 1;
      img {
        pointer-events: visible;
      }
    }
  }
  .ios-output {
    background: rgba(34, 34, 34, 0.752) !important;
  }
</style>

<figure
  class="output-container"
  class:isSaving
  class:ios-output={ifiOS}
  on:click={(evt) => {
    if (evt.target.tagName !== 'IMG') {
      isSaving = false
      dispatch('dismiss')
    }
  }}>
  <img src={dataUrl} alt="" />
  {#if navigator.standalone}
    <button
      on:click={() => {
        downloadImgURL(dataUrl)
      }}>
      保存/Save
    </button>
  {:else}
    <figcaption>
      长按图片保存
      <br />
      Long press image to save.
    </figcaption>
  {/if}
</figure>
