<script>
  import ImgSaver from '../ImgSaver.svelte'
  import html2canvas from 'html2canvas'
  import OaFooter from '../OaFooter.svelte'
  import QRCode from '../qr'
  // import Metascraper from "metascraper";

  let url = '',
    qrUrl = '',
    urlBrief = '',
    title = '',
    cStyle = '',
    brief = ''
  let parsedResponse = {}
  let imgUrl, card
  let hide = true,
    pending = false
  let isSaving = false
  let tmpcanva = document.createElement('canvas')

  function genCard(evt) {
    pending = true
    QRCode.toDataURL(url, {margin: 0}).then((u) => {
      qrUrl = u
    })
    hide = false
    // fetch("https://scrapingant.p.rapidapi.com/get?url=" + url, {
    //   method: "GET",
    //   headers: {
    //     "x-rapidapi-host": "scrapingant.p.rapidapi.com",
    //     "x-rapidapi-key": "bfaf9e74ddmshbf775818a09a42fp17045cjsn0aabea9b84d3"
    //   }
    // })
    //   .then(response => {
    //     console.log("ant", response);
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
    const m = url.match(/[http|https]/)
    if (!m) {
      url = 'https://' + url
    }
    console.log(url, m)
    fetch('https://fetch.envl.workers.dev/' + url)
      .then((rsp) => {
        console.log(rsp)
        return rsp.text()
      })
      .then((rst) => {
        parsedResponse = new window.DOMParser().parseFromString(
          rst,
          'text/html',
        )
        const tml = parsedResponse.body

        // const metadata = metascraper({ tml, url });
        // console.log("meta", metadata);
        // let tmpT = rst.match(/(?<=og:title.*content=).*(?=\/>)/);
        // tmpT = tmpT && tmpT[0].trim();
        title = parsedResponse.title || ''
        // let tmpB = rst.match(/(?<=og:description.*content=).*(?=\/>)/);
        // tmpB = tmpB && tmpB[0].trim();
        let b = parsedResponse.querySelector('p')
        b = b && b.innerText
        brief = b || ''
        // console.log("rst", rst, parsedResponse.window, title, brief);
        // gen img
        setTimeout(() => {
          window.scrollTo(0, 0)
          html2canvas(card).then((canva) => {
            tmpcanva.width = canva.width - 3
            tmpcanva.height = canva.height - 3
            tmpcanva.getContext('2d').drawImage(canva, -2, -2)
            imgUrl = tmpcanva.toDataURL('image/png', 1)
            isSaving = true
            pending = false
          })
        }, 500)
      })
  }
</script>

<style lang="scss">
  .container {
    display: grid;
    width: 100vw;
    height: 100vw;
    color: white;
    grid-template:
      'heading heading' 1fr
      'brief brief' 2fr
      'url-brief  qr' 64px / 3fr 1fr;
    h1 {
      grid-area: heading;
      align-self: center;
      padding: 1rem 2rem;
      margin: 0;
    }
    .brief {
      padding: 2rem;
      grid-area: brief;
      align-self: center;
    }
    .url-brief {
      text-overflow: ellipsis;
      overflow: hidden;
      align-self: center;
      grid-area: url-brief;
      padding: 0 0 0.8rem 2rem;
      &:before {
        content: '来源: ';
      }
    }
    figure {
      grid-area: qr;
      background: white;
      width: 50px;
      // height: 50px;
      border: none;
      object-fit: contain;
      // margin:10%;
      padding: 10%;
      justify-self: center;
      margin: -20px 0 0 0;
      align-self: center;
      img {
        width: 100%;
      }
      figcaption {
        text-align: center;
        background: white;
        // padding: 3px 6px;
        color: #444;
        font-size: 0.5rem;
        margin: 3px 0 0;
      }
    }
  }
  .hide {
    display: none;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: fixed;
    textarea {
      width: 90%;
      height: 3rem;
      outline: none;
      border: 2px dashed rgb(59, 59, 187);
      background: white;
    }
    button {
      border: 2px dashed rgb(59, 59, 187);
      border-radius: 50%;
      width: 4rem;
      height: 4rem;
      margin: 2rem;
      font-weight: bold;
      color: rgb(59, 59, 187);
      background: none;
      &.pending {
        animation: pending 2s infinite ease-in-out;
      }
      @keyframes pending {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
</style>

<svelte:head>
  <title>雾联网</title>
</svelte:head>

<svg>
  <foreignObject>
    <div bind:this={card} class="container" class:hide style={cStyle}>
      <h1>{title}</h1>
      <div class="brief">{brief}</div>
      <div class="url-brief">{url}</div>
      <figure>
        <img src={qrUrl} alt="" />
        <figcaption>长按查看页面</figcaption>
      </figure>
    </div>
  </foreignObject>
</svg>

<form on:submit|preventDefault={genCard}>
  <textarea type="text" required bind:value={url} placeholder="请粘贴链接" />
  <button class:pending type="submit">生成卡片</button>
</form>
<OaFooter style=" width: 100%; padding: 0;bottom: 20px; position: absolute;" />
<ImgSaver
  bind:isSaving
  dataUrl={imgUrl}
  on:dismiss={() => {
    hide = true
  }} />
