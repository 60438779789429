<script>
  import { onMount } from "svelte";
  let ntl = Array.from(Array(30))
    .map((v, i) => {
      const c = Math.random();
      return {
        time: i,
        txt:
          c > 0.8
            ? "赞了你的动态"
            : c > 0.5
            ? "赞了你的评论"
            : c > 0.3
            ? "评论了你"
            : "回复了你"
      };
    })
    .reverse();
  const alph = "abcdefghijklmnopqrstuvwxyz";
  let pml = alph.split("");
  let dividerTxt = "通知消息(留言 点赞)👆👇";

  onMount(() => {
    document.querySelector(".jk-msg").scrollTop = 1000;
    document.querySelector(".notif-area").scrollTop = 1000;
  });
</script>

<style lang="scss">
  .jk-msg {
    height: 100vh;
    background: rgb(32, 32, 32);
    overflow: scroll;
  }
  .notif-area {
    margin: 0;
    background: rgb(222, 184, 135);
    overflow: scroll;
    scrollbar-color: green;
    z-index: 11;
    position: sticky;
    height: 90%;
    top: -80%;
  }
  .pm-area {
    position: sticky;
    bottom: -80%;
    height: 90%;
    z-index: 11;
    background: rgb(141, 135, 222);
    margin: 0;
    overflow: scroll;
  }
  .divider {
    position: sticky;
    top: 0;
    bottom: 0;
    height: 70px;
    width: 100%;
    background: #f88;
    z-index: 1111;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  li {
    height: 40px;
    &:not(:first-child) {
      border: solid black;
      border-width: 1px 0 0 0;
    }
    padding: 3px 0;
    margin: 9px 0;
    .time {
      font-size: 0.8rem;
    }
  }
  .user {
    font-weight: bold;
    text-transform: capitalize;
  }
</style>

<div class="jk-msg">
  <ul class="notif-area">

    {#each ntl as li (li + Math.random())}
      <li>
        <div class="time">{li.time}分钟前</div>
        {'用户' + ((Math.random() * 100) | 1) + ' ' + li.txt}
      </li>
    {/each}
  </ul>
  <div class="divider">
    <div>通知消息(留言 点赞)👆</div>
    <div>聊天记录(日记留言)👇</div>
  </div>
  <ul class="pm-area">
    {#each pml as li (li + ' ' + Math.random())}
      <li>
        <div class="user">用户{li}</div>
        <div>
          <span>blablablabla</span>
          <span class="pm-time">
            {alph.indexOf(li) * 3 + (Math.random(3) | 1)}分钟以前
          </span>
        </div>
      </li>
    {/each}
  </ul>
</div>
