<script>
  import { onMount } from 'svelte'
  import { Perlin } from '../perlin'
  import { clamp, ifiOS, ifWX, map, E } from '../helpers.svelte'
  import ICON from './ICONS.svelte'
  import CardBuilder from './CardBuilder.svelte'

  let WALLET_STYLES = ['wallet-t-0', 'wallet-t-2']
  // if (!ifiOS) WALLET_STYLES.push("wallet-t-1");
  let wStyle = 0 //wallet style

  let currentIndex
  let isSaving = false
  let builderFields
  let onCardAdded
  let genCard
  let opts = {
    adding: false, //adding card
  }

  // QR.toDataURL("test").then(url => {
  //   tmpURL = url;
  // });
  let cards = []
  $: cardViewing = Array(cards.length + 2).fill(false) // extra 1 for cover 1 for iftech

  let pn = new Perlin(Math.random())
  // iftech是张特殊卡,自动生成,无法创建
  $: posSpan = wStyle === 1 ? 85 : 3
  let iftech = []
  $: iftech =
    // ['jstore','pingpal','orange','comeet','universe','jellow','jike','kuai','yiguan'].map((type,i)=>{return{type,pos:[(Math.random()-.5)*posSpan,(Math.random()-.5)*posSpan,E.easeOutCubic(Math.random()-.5)*posSpan]}});
    cards
      .filter(c =>
        [
          'jstore',
          'pingpal',
          'orange',
          'comeet',
          'universe',
          'jellow',
          'jike',
          'kuai',
          'yiguan',
          'shengchen',
        ].includes(c.type)
      )
      .map((card, i) => {
        return { ...card, pos: [0, 0, 0] }
      })
  // console.log("llllllll", iftech);

  $: if (isSaving === false) {
    ifOpts.offsets.fill(0)
  }
  let ifOpts = {
    isPlaying: false,
    play: () => {
      ifOpts.isPlaying = true
      isSaving = true
      ifOpts.offsets.fill(0)
    },
    stop: () => {
      isSaving = false
      ifOpts.isPlaying = false
      ifOpts.offsets.fill(0)
    },
    icre: index => {
      ifOpts.offsets[index] += ifOpts.pnRates[index]
    },
    offsets: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    scalar: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map(
      () => Math.random() * 0.8 + 1
    ),
    pnRates: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map(
      () => Math.random() * 0.01 + 0.0019
    ),
  }
  function step() {
    iftech.forEach((e, i) => {
      ifOpts.icre(i)
      const [x, y, z] = e.pos
      e.pos = [
        (pn.noise(ifOpts.offsets[i] + 3 + 12 * i, 0, 0) - 0.5) *
          posSpan *
          ifOpts.scalar[i],
        (wStyle === 1 ? 1 : 11) *
          (pn.noise(ifOpts.offsets[i] + 75 + i * 34.8, 0, 0) - 0.5) *
          posSpan *
          ifOpts.scalar[i],
        E.easeOutCubic(pn.noise(ifOpts.offsets[i] + 36 + 26.8 * i, 0, 0)) *
          posSpan,
      ]

      // console.log('anim',e.pos)
    })
    iftech = [...iftech]
    if (isSaving && cardViewing[cards.length]) {
      window.requestAnimationFrame(step)
    }
  }

  function posCSS([x, y, z]) {
    const tmp = `transform:translate(${x}vw,${y}vw) scale(${
      0.5 + z / posSpan
    });`
    return tmp
  }

  function addBtnHandler() {
    // TODO insert a new card and save to localstorage
    if (opts.adding) {
      genCard()
    } else {
      // for(const k of Object.keys(builderFields)){
      //   builderFields[k]=''
      // }
      // builderFields.cardType='custom'
      builderFields = null
      document
        .querySelector('.container')
        .scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      opts.adding = true
    }
  }

  onMount(() => {
    const tmp = localStorage.getItem('oa-cards')
    if (tmp) cards = JSON.parse(tmp)
    // console.log("sto", tmp);
    // window.requestAnimationFrame(step);
    // TODO read cards from localStorage
  })
</script>

<main tabindex="-1" class:adding={opts.adding}>
  <div class="drawer">
    <CardBuilder
      bind:rFields={builderFields}
      on:selectType={evt => {}}
      on:formFilled={evt => {}}
      on:cardReady={evt => {
        cards = [
          ...cards,
          {
            index: cards.length,
            css: `  background-color:rgb(${145 * Math.random() + 30},${30 + 155 * Math.random()},${30 + 175 * Math.random()});
        `,
            ...builderFields,
          },
        ]
        localStorage.setItem('oa-cards', JSON.stringify(cards))
        opts.adding = false
      }}
      bind:genCard
    />
  </div>
  <div class="header">
    <h3
      on:click={evt => {
        if (opts.adding) {
          opts.adding = !opts.adding
        } else {
          wStyle = (wStyle + 1) % WALLET_STYLES.length
        }
      }}
    >
      {opts.adding ? 'Cancel' : 'Style'}
    </h3>
    <h3 on:click={addBtnHandler}>{opts.adding ? 'Done' : 'Add'}</h3>
  </div>
  <div class:isSaving class="card-list" class:verticle={wStyle !== 0}>
    {#each [...cards].reverse() as card}
      <div
        class:viewing={cardViewing[card.index]}
        class:card-exclusive={isSaving && cardViewing[card.index]}
        class={'card ' + card.type + ' ' + WALLET_STYLES[wStyle]}
        style={`${card.css}
        top:${card.index * 2}px;
        `}
        on:click={evt => {
          if (cardViewing[card.index]) {
          } else {
            cardViewing.fill(false)
            cardViewing[card.index] = true
            currentIndex = card.index
          }
        }}
      >
        <div class="card-title">{card.title}</div>
        <div class="card-content">
          <img src={card.image} alt="" class:fkHidden={card.url.length === 0} />
          <div class="card-sub">{card.sub}</div>
          <div class="card-info">{card.info}</div>
        </div>
        <i
          class="card-logo"
          on:click={evt => {
            if (cardViewing[card.index]) isSaving = true
          }}
        >
          <ICON name={card.type} autoHW="true" />
        </i>
      </div>
    {/each}
    {#if iftech.length > 0}
      <div
        class:card-exclusive={isSaving && cardViewing[cards.length]}
        class={'card if-tech ' + WALLET_STYLES[wStyle]}
        on:click={() => {
          if (cardViewing[cards.length]) {
          } else {
            cardViewing.fill(false)
            cardViewing[cards.length] = true
          }
        }}
      >
        <div class="card-title">
          <i>IF UNIVER$E</i>
        </div>
        <div
          class="card-content"
          on:click={() => {
            if (cardViewing[cards.length]) {
              if (!isSaving) {
                ifOpts.play()
                window.requestAnimationFrame(step)
              } else {
                ifOpts.stop()
              }
            }
          }}
        >
          {#each iftech as icon}
            <i
              style={posCSS(icon.pos) +
                ` width: ${Math.min(50, 85 / iftech.length)}vw;
      height: ${85 / iftech.length}vw;`}
              class:idle={!isSaving || !cardViewing[cards.length]}
            >
              <ICON name={icon.type} autoHW="true" />
            </i>
          {/each}
        </div>
      </div>
    {/if}
    <!-- {:else} -->
    <!-- else content here -->
    <div
      class={'card cover ' + WALLET_STYLES[wStyle]}
      class:card-exclusive={isSaving && cardViewing[cards.length + 1]}
      style={`  background-color:rgb(${145 * Math.random() + 30},${30 + 155 * Math.random()},${30 + 175 * Math.random()});
        `}
      on:click={evt => {
        cardViewing.fill(false)
      }}
    />
  </div>
</main>
<div
  class="bg-mask"
  class:isSaving
  on:click={evt => {
    isSaving = false
    cardViewing.fill(false)
    ifOpts.stop()
  }}
/>
<button
  class="btn-del"
  class:isSaving={isSaving && !ifOpts.isPlaying}
  on:click={evt => {
    let delI
    isSaving = false
    cardViewing.fill(false)
    cards.forEach(c => c.index > currentIndex && (c.index -= 1))
    cards.splice(currentIndex, 1)
    cards = [...cards]
    localStorage.setItem('oa-cards', JSON.stringify(cards))
  }}
>
  <ICON name="trash" autoHW="true" />
</button>

<style lang="scss">
  :root {
    --drawerH: 240px;
    --cardCase-bg: #241b2f;
    --cardCase-bg-sub: #262335;
    --cardCase-bg-light: #463465;
    --cardCase-white: #ecebec;
  }
  .drawer {
    width: 100%;
    height: var(--drawerH);
    background: var(--cardCase-bg);
  }
  main {
    --cardW: 85vw;
    --topMargin: 35%;
    --anim-quick: 0.25s all ease-out;
    --anim-mid: 0.5s all ease-in-out;
    transform: translateY(calc(0px - var(--drawerH)));
    background: var(--cardCase-bg);
    position: fixed;
    height: calc(100vh + var(--drawerH));
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: var(--anim-mid);
    &.adding {
      transform: translateY(0);
    }
  }
  .header {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto calc((100vw - var(--cardW)) / 2);
    background: var(--cardCase-bg);
    color: white;
  }
  .card-list {
    color: white;
    padding: 0 0 20vh;
    display: flex;
    flex-direction: column;
    margin-top: var(--topMargin);

    &.verticle {
      margin-top: calc(var(--topMargin) + 20%);
    }
    &.isSaving {
      .card:not(.card-exclusive) {
        opacity: 0;
      }
    }
  }
  .card {
    color: white;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    width: var(--cardW);
    height: calc((54 / 90) * var(--cardW));
    position: sticky;
    border-radius: 12px;
    transition: all 0.25s ease-out;
    padding: 3px 8px;
    overflow: hidden;
    user-select: none;
    margin: auto;
    margin-top: calc(0% - var(--topMargin));
    // &.viewing,
    &:hover {
      & + .card {
        margin-top: 2%;
      }
    }
    // &:nth-child(2) {
    //   margin-top: -15%;
    // }
    transform: rotate3d(0);

    .card-content {
      z-index: 10;
      pointer-events: none;
      user-select: none;
      img {
        width: 4rem;
        height: 4rem;
        background: var(--cardCase-white);
        border-radius: 5px;
        border: 4px solid var(--cardCase-white);
        user-select: none;
      }
    }

    .card-title {
      z-index: 10;
      font-size: 1rem;
      transition: font-size 0.25s ease-out;
      user-select: none;
    }
    .card-logo {
      width: 150px;
      height: 150px;
    }
  }

  .icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  //////// Wallet Styles ////////
  //// Wallet Styles ////////////

  .wallet-t-0 {
    // normal
    transform: rotate3d(0);
    // box-shadow: 0px 0px 7px 0px #0005;
    box-shadow: 2px -1px 0px 2px #0006;
    // &.viewing,
    &:hover {
      transform: rotateX(0);
      .card-title {
        font-size: 1rem;
      }
    }
    .card-exclusive {
      .card-content {
        margin-top: -25% !important;
      }
    }
    .card-title {
      letter-spacing: 0px;
      font-size: 1.8rem;
      padding: 0.73rem 0.56rem;
    }
    .card:last-child .card-title {
      font-size: 1rem;
    }
    .card-content {
      // text-align: center;
      padding: 0 0 0 0.56rem;
      .card-sub {
        color: #fafafa;
        margin: 3% 0 0.1rem;
        font-size: 1.8rem;
      }
      .card-info {
        font-size: 1.2rem;
      }
    }
    .card-logo {
      z-index: -1;
      position: absolute;
      right: -10vw;
      top: -8vw;
      width: 45vw;
      height: 45vw;
      transform: rotate(0deg);
    }
  }
  .wallet-t-1 {
    // verticle 转置
    transform: rotate3d(1, 1, 0, 180deg);
    box-shadow: 2px 3px 0px 2px #0006;
    &:hover {
      // box-shadow: 0px 6px 32px 0px #0005;
      margin-bottom: 15%;
    }
  }
  .wallet-t-2 {
    // verticle spin
    transform: rotate3d(0, 0, -1, 90deg);
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    // box-shadow: 0px 0px 6px 0px #0005;
    box-shadow: 1px 3px 0px 2px #0006;
    // &.viewing,
    &:hover {
      // box-shadow: 2px -1px 0px 2px #0006;
      // box-shadow: -5px 0px 32px 0px #000;
      transform: rotateX(0) translateY(2%);
      top: -100vh !important;
      margin-bottom: 22%;
      .card-logo {
        transform: rotate(0deg) scale(1);
      }
    }
    &.card-exclusive {
      transform: none;
    }
    &.if-tech {
      .card-content {
        width: fit-content;
        flex-flow: column wrap !important;
        height: 90px !important;
        margin: auto !important;
        align-content: center !important;
      }
    }
    .card-content {
      margin-left: 0.4rem;
      img {
        position: absolute;
        bottom: 0;
        margin-bottom: 0.9rem;
      }
    }
    .card-title {
      font-size: 1rem;
      // position: absolute;

      justify-self: flex-end;
      padding: 0.6rem 0rem;
      color: #fafafa;
      writing-mode: vertical-lr;
      text-orientation: sideways;
      -webkit-text-orientation: sideways;
    }
    .card-sub {
      color: #fafafa;
      margin-top: 0.3rem;
      font-size: 2rem;
    }
    .card-logo {
      z-index: -1;
      position: absolute;
      right: -20px;
      bottom: -30px;
      transform: rotate(0deg) scale(0.6) translate(40px, 20px) rotate(90deg);
      transition: transform 0.25s ease-out;
    }
  }

  //// Card styles ////////////
  //////// Card Styles ////////
  //////////// Card Styles ////
  .if-tech {
    background-color: rgba(2, 2, 2, 0.45);
    backdrop-filter: blur(9px);
    -webkit-backdrop-filter: blur(9px);
    // color: #222;
    overflow: visible;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // visibility: visible;
    // transition: all .25s ease-out;
    &.card-exclusive {
      background-color: #aaa6;
    }
    .card-content {
      justify-content: center;
      align-items: center;
      display: flex;
      margin: 15% 0;
      height: fit-content;
      pointer-events: visible;
    }
    i {
      background: transparent;
      z-index: 19999;
      // transition: none;
      &.idle {
        position: relative;
        margin: 3px;
        width: 26px !important;
        height: 26px !important;
        box-shadow: none;
        transition: all 0.5s ease-in-out;
        transform: translate(0, 0) !important;
      }
    }
  }
  // .cover {
  //   background-color: rgba(156, 156, 156, 0.45);
  //   backdrop-filter: blur(9px);
  //   -webkit-backdrop-filter: blur(9px);
  // }
  //---------------------------------//
  // .card-exclusive {
  //     margin-top: 60vh;
  // position: absolute;
  // margin-left: 5vw;
  // margin-bottom: 100%;
  // margin-top: 100%;
  // }
  .bg-mask {
    z-index: 1112;
    padding: 0;
    margin: 0;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background: transparent;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.8s;
    // backdrop-filter: blur(12px);
    // -webkit-backdrop-filter: blur(12px);
    pointer-events: none;
    img {
      pointer-events: visible;
      object-fit: contain;
      border-radius: 15px;
      width: 70%;
      position: relative;
      margin-top: 10px;
      margin-bottom: 25%;
    }
    figcaption {
      margin-top: 10%;
      position: relative;
      color: white;
      text-align: center;
    }
    display: none;
    &.isSaving {
      display: inherit;
      // background: #222b;
      pointer-events: visible;
      // opacity: 1;
    }
  }
  .btn-del {
    width: 15vw;
    height: 40px;
    border-radius: 13px;
    bottom: 3vh;
    left: 42.5vw;
    color: white;
    background: #ed5e68;
    visibility: hidden;
    pointer-events: none;
    position: fixed;
    margin: auto;
    z-index: 9999;
    border: none;
    &.isSaving {
      visibility: visible;
      pointer-events: visible;
    }
  }
  .fkHidden {
    visibility: hidden !important;
  }
</style>
