<script>
  import OaFooter from "../OaFooter.svelte";
  import firebase from "firebase/app";
  import "firebase/firestore";
  import { v1 as uuidv1 } from "uuid";
  import { onMount } from "svelte";
  import ICON from "../icons.svelte";

  let list = localStorage.getItem("check-list");
  if (list) {
    console.log("loaded list");
    list = JSON.parse(list);
  } else {
    list = [
      { text: "和很久没联系的朋友说说话", ticked: false },
      { text: "整理自己的房间", ticked: false },
      { text: "和爸妈视频聊聊天", ticked: false },
      { text: "假期坚持运动", ticked: false },
      { text: "拍张冰淇淋证件照", ticked: false },
      { text: "重温一部最爱的电影", ticked: false },
      { text: "通关一款游戏", ticked: false },
      { text: "和朋友聚会", ticked: false },
      { text: "今日步数大于10000", ticked: false },
      { text: "不喝奶茶挑战", ticked: false },
      { text: "某天使用手机小于两小时", ticked: false },
      { text: "读完一整本书", ticked: false }
    ];
  }
</script>

<style lang="scss">
  .root {
    user-select: none;
    -webkit-user-select: none;
    background: #fafafa;
    height: 100%;
  }
  .header {
    display: flex;
    height: 15vh;
    justify-content: space-between;
    align-items: center;
    figure {
      text-align: center;
      margin: 1.1rem 1.2rem 0.5rem 0;
    }
    img {
      object-fit: contain;
      width: 50px;
    }
    figcaption {
      text-align: center;
      font-size: 0.8rem;
      color: #333;
    }
  }
  h2 {
    color: white;
    text-shadow: 0 0 10px #ff2800, 0 0 12px #ff2800;
    font-size: 2rem;
    margin: 2rem 6%;
  }
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    // --color: #ff92bf;
    --color: #f5918c;

    > div {
      position: relative;
      width: 92%;
      display: grid;
      grid-template-columns: 1fr 20fr 1fr;
      // justify-content: space-between;
      align-items: center;
      font-size: 1.16rem;
      border-radius: 10px;

      padding: 0.25rem 0.3rem;
      // background: #fff0f0;
      // box-shadow: 1px 6px 6px #fff0f0;
      // box-shadow: 1px 6px 6px #3334;
      transition: all 0.3s ease-out;
      &:active {
        transition: all 0.1s ease-in;
        transform: translate(0, 3px);
      }
      &:nth-child(1) {
        border-color: #f5918c;
        // background: #f5918c;
        // box-shadow: 1px 6px 6px #ff6060;
        .square {
          background: #f5918c;
        }
      }
      &:nth-child(2) {
        border-color: #fab0ae;
        // background: #fab0ae;
        // box-shadow: 1px 6px 6px #ff8080;
        .square {
          background: #fab0ae;
        }
      }
      &:nth-child(3) {
        border-color: #fdd0d0;
        // background: #fdd0d0;
        // box-shadow: 1px 6px 6px #ffa0a0;
        .square {
          background: #fdd0d0;
        }
      }
      &:nth-child(4) {
        border-color: #fee0e0;
        // background: #fee0e0;
        // box-shadow: 1px 6px 6px #ffc0c0;
        .square {
          background: #fee0e0;
        }
      }
    }
  }
  .square {
    background: #fff0f0;
    width: 2rem;
    height: 2rem;
    border-radius: 6px;
    margin: 0 0.5rem;
  }
  .counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.4rem;
    width: 2rem;
    height: 1.2rem;
    transform: translateY(-0.3rem);
    span {
      margin-left: 0.1rem;
    }
  }
  .text {
    // justify-self: center;
    width: fit-content;
    // width: min-content;
    padding: 0 0.6rem;
    background-repeat: no-repeat;
    background-size: 30% 1.5px, calc(100% - 20px) 10px, calc(100% - 20px) 10px,
      10px 10px, 8px 8px;
    background-position: 100% calc(50% + 2px), center center, center center,
      2px 50%, calc(100% - 3px) calc(50% + 1px);
  }
  .ticked {
    background-image: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.5),
        transparent
      ),
      linear-gradient(2deg, var(--color) 50%, transparent 50%),
      linear-gradient(-0.9deg, var(--color) 50%, transparent 50%),
      linear-gradient(-60deg, var(--color) 50%, transparent 50%),
      linear-gradient(120deg, var(--color) 50%, transparent 50%);
  }
  .mask {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 150px;
    pointer-events: none;
    width: 100%;
    background: linear-gradient(0deg, white, #fff0);
  }
  .footer-wrapper {
    margin: 5rem 0;
  }
</style>

<svelte:head>
  <title>共享打卡</title>
</svelte:head>
<div class="root">
  <div class="header">
    <h2>
      <div>Jellow</div>
      <div>假期小挑战</div>
    </h2>
    <figure>
      <img src="assets/uc.jpg" alt="" />
      <figcaption>扫码开始挑战</figcaption>
    </figure>
  </div>
  <!-- <div>之假期小挑战</div> -->
  <form class="list">
    {#each list as item, index}
      <div
        on:click={() => {
          item.ticked = !item.ticked;
          const tmp = JSON.stringify(list);
          localStorage.setItem('check-list', tmp);
        }}>
        <div class="square" />
        <span class="text" class:ticked={item.ticked}>{item.text}</span>
      </div>
    {/each}
  </form>
  <div class="footer-wrapper">
    <OaFooter />
  </div>
  <div class="mask" />
</div>
