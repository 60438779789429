<script>
  import Zdog from "zdog";
  import { onMount } from "svelte";

  export let width = 240;
  export let height = 240;

  function initZdog() {
    const tau = Zdog.TAU;
    let illo = new Zdog.Illustration({
      element: ".crown-canvas",
      dragRotate: true,
      scale: 0.6,
      rotate: { x: 170 }
    });
    let origin = new Zdog.Anchor({
      addTo: illo,
      rotate: { x: tau / 4 },
      translate: { y: 32 }
    });
    let can = new Zdog.Cylinder({
      addTo: origin,
      diameter: 150,
      length: 90,
      stroke: false,
      color: "#fee260",
      frontFace: "#feaab3",
      backface: "#feaabc"
    });
    let triDot = new Zdog.Group({
      addTo: illo,
      translate: { z: 74 }
    });
    let corn = new Zdog.Shape({
      addTo: triDot,
      path: [
        // triangle
        { x: 0, y: -80 },
        { x: 70, y: 40 },
        { x: -70, y: 40 }
      ],
      translate: { x: 0, y: -30, z: 0 },
      // rotate:{x:Zdog.TAU/4,z:0,y:0},
      scale: 0.5,
      // closed by default
      fill: true,
      stroke: 2,
      color: "#fee260"
    });
    new Zdog.Shape({
      addTo: triDot,
      translate: { y: -80, z: 0 },
      stroke: 28,
      color: "#636"
    });
    triDot.copyGraph({
      translate: { z: -74, y: 0 }
    });

    triDot.copyGraph({
      translate: { z: -37, y: 0, x: 61 },
      rotate: { y: 45 }
    });
    triDot.copyGraph({
      translate: { z: 37, y: 0, x: 61 },
      rotate: { y: -45 }
    });

    triDot.copyGraph({
      translate: { z: -37, y: 0, x: -61 },
      rotate: { y: -45 }
    });
    triDot.copyGraph({
      translate: { z: 37, y: 0, x: -61 },
      rotate: { y: 45 }
    });

    function animate() {
      illo.rotate.y += 0.021;
      illo.updateRenderGraph();
      requestAnimationFrame(animate);
    }

    animate();
  }
  onMount(() => {
    initZdog();
  });
</script>

<style>
  .crown-canvas {
    width: 95% !important;
    height: auto !important;
    background: #ffddbb;
    border-radius: 12px;
  }
</style>

<canvas class="crown-canvas" {width} {height} />
