<script>
  import { lang, isCN } from './lang/strings'
  import OaFooter from './OaFooter.svelte'
  import { onMount } from 'svelte'
  import QRCode from './qr'
  import Zdog from 'zdog'
  import UFO from './zdog/ufo.svelte'
  import CROWN from './zdog/crown.svelte'
  import ChiaHouse from './ChiaHouse.svelte'
  // ---------- items
  const items = [
    {
      url: 'https://stkm.gnimoay.com',
      img: 'assets/stkm.png',
      title: 'STKM',
      brief: isCN
        ? '一个别致的拍照App'
        : 'A Camera for shooting stacking photos',
    },
    {
      url: '#/square',
      img: 'assets/square.png',
      title: isCN ? '方块日记' : 'Square Diary',
      brief: isCN ? '在卡片上写字' : 'Generate cards with letters',
    },
    {
      url: '#/tangtou',
      img: 'assets/yuqian.png',
      title: isCN ? '烫头' : 'Photo Resampler',
      brief: isCN ? '给照片烫个卷发' : 'Artistic photo stylizer',
    },

    {
      url: 'https://observablehq.com/@gnimoay/conways-circle',
      img: 'assets/conway2.png',
      title: isCN ? '康威圆' : "Conway's Circle",
      brief: isCN ? '一个有趣的圆' : 'An interesting circle',
    },
    {
      url: 'https://observablehq.com/@gnimoay/juan',
      img: 'assets/ddj.png',
      title: isCN ? '大大卷' : 'Involution',
      brief: 'Rock & Roll',
    },

    {
      url: 'https://observablehq.com/@gnimoay/untitled/4',
      img: 'assets/starRead.png',
      title: isCN ? '星战阅读' : 'StarWreading',
      brief: isCN ? '「看」而不是「读」文章' : 'Watch articles',
    },
    {
      url: '#/card',
      img: 'assets/cards.png',
      title: isCN ? '数字名片' : 'CyberCards',
      brief: isCN ? '数字生活名片夹' : 'Name cards for cyber life',
    },
    {
      url: '#/chinchinois',
      img: 'assets/xinhua.png',
      title: isCN ? '新新话' : 'ChinChinois',
      brief: isCN ? '讲点「新话」' : 'Chinese with deliberate ambiguity',
    },
    {
      url: '#/qc',
      img: 'assets/ma.png',
      // img: "special/qc",
      title: isCN ? "Ma'" : 'QuickCode',
      brief: isCN
        ? '也许是最快的二维码生成器'
        : 'Probably the fastest QR Code generator',
    },
    {
      url: 'https://observablehq.com/@gnimoay/untitled/3',
      img: 'assets/bottle.png',
      title: '小瓶子',
      brief: '就是那个解压的涂瓶子工具',
    },
    {
      url: '#/fresh-rate',
      // img: 'assets/bottle.png',
      emoji: '👀',
      title: '看看你的',
      brief: '屏幕刷新率是多少',
    },
    // {
    //   url: "#/intranator",
    //   img: "assets/intra.png",
    //   title: isCN ? "雾联网(施工中)" : "Intranator(WIP)",
    //   brief: isCN ? "网址卡片生成器,送给残缺的互联网" : "URL Card Generator"
    // }
    // {
    //   url: "#/uncheck",
    //   img: "assets/check.jpg",
    //   title: "共享计划(低配版)",
    //   brief: "要不，我们一起干点啥?"
    // },
    // {
    //   url: "#/check",
    //   img: "assets/check.jpg",
    //   title: "共享计划",
    //   brief: "要不，我们一起干点啥?"
    // },
    // {
    //   url: "#/jk-msg",
    //   img: "assets/jk-msg.png",
    //   title: "通知页面Demo",
    //   brief: "对通知页面的一点想法"
    // }
  ]
  //////////
  let displayMode = 'browser'
  let qrURL = ''
  let bgHue = 122,
    bgColor = 'white',
    hueTimer
  const itemBGs = [
    '#bbe9ff',
    '#cfffbb',
    '#febbff',
    '#bbdaff',
    '#aaffe4',
    '#feffbb',
    '#ffbbbb',
    '#eebbff',
    '#c2bbff',
    '#bbffc3',
  ]

  onMount(() => {
    document.documentElement.style.setProperty(
      '--rootH',
      window.innerHeight + 'px'
    )
    QRCode.toDataURL('https://toys.gnimoay.com', { margin: 0 }).then(
      u => (qrURL = u)
    )
    const changeHue = () => {
      bgHue += 2
      bgHue = bgHue > 360 ? 0 : bgHue

      document
        .querySelector('.root')
        .style.setProperty('background-color', `hsl(${bgHue}, 36%, 71%)`)
      // requestAnimationFrame(changeHue);
    }
    hueTimer = setInterval(changeHue, 200)
    // changeHue();

    // if ("serviceWorker" in navigator) {
    //   navigator.serviceWorker.register("/service-worker.js");
    // }
    // window.addEventListener("beforeinstallprompt", e => {
    //   // Prevent the mini-infobar from appearing on mobile
    //   // e.preventDefault();
    //   // Stash the event so it can be triggered later.
    //   // deferredPrompt = e;
    //   // Update UI notify the user they can install the PWA
    //   // showInstallPromotion();
    // });
    const onContentLoaded = () => {
      if (navigator.standalone) {
        displayMode = 'standalone-ios'
      }
      if (window.matchMedia('(display-mode: standalone)').matches) {
        displayMode = 'standalone'
      }
      // Log launch display mode to analytics
      console.log('DISPLAY_MODE_LAUNCH:', displayMode)
    }
    window.addEventListener('DOMContentLoaded', onContentLoaded)

    return () => {
      window.removeEventListener('DOMContentLoaded', onContentLoaded)
      clearInterval(hueTimer)
      console.log('un mount default page')
    }
  })
</script>

<svelte:head>
  <title>{lang.home.title}</title>
</svelte:head>

<div class={'root ' + displayMode}>
  {#if window.innerWidth >= 768}
    <div class="notice">
      <img src={qrURL} alt="" />
      <div class="notice-txt">
        {isCN
          ? '本页面内容全部为手机而开发'
          : 'This page was built for mobile devices'}
      </div>
    </div>
  {/if}
  <div class={'container ' + displayMode}>
    <div class="items">
      <h1><span>{lang.home.title0}</span> <span>{lang.home.title1}</span></h1>
      <CROWN />
      <ChiaHouse />

      {#each items as item, i}
        <a
          href={item.url}
          style={`background-color:${itemBGs[i % itemBGs.length]};`}
        >
          <figure>
            {#if item.img}
              <img src={item.img} alt={item.img} />
            {:else if item.emoji}
              <div style="font-size: 32px;">{item.emoji}</div>
            {/if}
            <figcaption>
              <strong>{item.title}</strong>
              <div>{item.brief}</div>
            </figcaption>
          </figure>
        </a>
      {/each}
      <UFO style="width:95%!important;background:red;" />
      <div class="zmd" class:odd={items.length % 2 !== 0}>
        <span>要是觉得好玩，也可以订阅这份邮报看看:</span>
        <a target="_blank" href="https://zmd.hedwig.pub">芝麻地</a>
      </div>
    </div>
  </div>

  <OaFooter />
</div>

<style lang="scss">
  .root {
    --rootW: min(100vw, 60vh);
    // --rootH: 100%;
    width: var(--rootW);
    height: var(--rootH);
    margin: auto;
    // &.standalone,
    // &.standalone-ios {
    //   position: fixed;
    // }
  }
  .container {
    color: #333;
    width: 92%;
    box-shadow: 0 4px 11px #b0b0b088;
    background-color: white;
    border-radius: 16px;
    position: relative;
    padding: 1rem 0.5rem;
    // padding: 1rem 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5% auto 0;
    height: 86%;
    h1 {
      text-align: center;
      margin: 0 0 10% 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transform: translate(0, 2rem);
      span {
        display: inline-block;
        --anim-dur: 2s;
        &:first-child {
          animation: var(--anim-dur) linear infinite rotateAround;
        }
        &:last-child {
          animation: var(--anim-dur) linear infinite rotateAround;
          animation-delay: 1s;
        }
      }
    }
  }

  .zdog-canvas {
    // position: absolute;
    justify-self: center;
    align-self: center;
    width: 95% !important;
    height: auto !important;
    background: #bbe9ff;
    border-radius: 12px;
    // width: min(35vw, 200px, 25vh) !important;

    // height: min(35vw, 200px, 25vh) !important;
  }
  .zmd {
    width: 55% !important;
    border-radius: 12px;
    justify-self: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.odd {
      grid-column: 1 / span 2;
    }
    a {
      margin-top: 5px;
      width: max-contetn;
      padding: 2px 6px;
      border-radius: 3px;
      font-weight: bold;
      color: white;
      background: #333;
    }
  }

  .items {
    width: 100%;
    // height:
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 50% 50%;
    grid-row-gap: 0.6rem;
    justify-items: center;
    overflow-y: scroll;
  }
  a {
    // width: 50%;
    border-radius: 12px;
    color: currentColor;
    // scroll-snap-align: end;
    width: 95%;
    max-height: 50vw;
    display: flex;
    justify-content: center;
    text-decoration: none;
    transition: 0.2s ease-out transform;
    &:active {
      filter: brightness(0.6);
    }
  }
  figure {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 1.2rem 0;
    padding: 0;
    // padding: 0;
    // margin: 0;
    img {
      border-radius: 18px;
      box-shadow: 2px 3px 6px #9e9e9e;
      object-fit: cover;
      margin-bottom: 10px;
      width: 64px;
      height: 64px;
    }
    figcaption {
      text-align: center;
      padding: 0 0.3rem;
    }
  }
  .fk-hidden {
    visibility: hidden;
  }
  .notice {
    position: absolute;
    bottom: 30px;
    left: 30px;
    img {
      background: white;
      padding: 6px;
      border-radius: 4px;
    }
    .notice-txt {
      background: #493bbba6;
      color: white;
      &:before {
        content: '*';
      }
    }
  }
  @media (prefers-color-scheme: dark) {
    .container {
      background: #3a3b3c;
      h1 {
        color: wheat;
      }
    }
    .zmd {
      color: wheat;
      a {
        color: black;
        background: wheat;
      }
    }
  }
  @keyframes rotateAround {
    0% {
      transform: rotateX(0);
      /* perspective-origin: center; */
      transform-origin: 0 0 2rem;
    }
    100% {
      transform: rotateX(-360deg);
      /* perspective-origin: center; */
      transform-origin: 0 0 2rem;
    }
  }
  @keyframes pulse {
    10% {
      transform: scale(1);
      filter: brightness(1);
    }
    40% {
      transform: scale(1.6);
      filter: brightness(3.8);
    }
    // 70% {
    //   transform: scale(1.5);
    //   filter: brightness(3.8);
    // }
    80% {
      transform: scale(0.85);
      filter: brightness(1.3);
    }
    100% {
      transform: scale(1);
      filter: brightness(1);
    }
  }
</style>
