<script>
  import OaFooter from "../OaFooter.svelte";
  import firebase from "firebase/app";
  import "firebase/firestore";
  import { v1 as uuidv1 } from "uuid";
  import { onMount } from "svelte";
  import ICON from "../icons.svelte";

  let data = localStorage.getItem("wecheck-data");
  if (data) {
    data = JSON.parse(data);
  } else {
    data = { uuid: uuidv1(), items: {}, score: 0 };
  }

  let TOHandle;
  const increment = firebase.firestore.FieldValue.increment(1);
  const decrement = firebase.firestore.FieldValue.increment(-1);
  // let itemList = [];
  $: itemList = Object.keys(data.items)
    .map(k => data.items[k])
    .sort((a, b) => b.amount - a.amount);

  onMount(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyCJdlzhnQdsONLmv1KGQIHY9FhiChyqCBA",
      authDomain: "oa-site.firebaseapp.com",
      databaseURL: "https://oa-site.firebaseio.com",
      projectId: "oa-site",
      storageBucket: "oa-site.appspot.com",
      messagingSenderId: "926252173661",
      appId: "1:926252173661:web:8b170a46a40e78405380b4",
      measurementId: "G-YW09J10NBL"
    };
    firebase.initializeApp(firebaseConfig);
    // firebase.firestore().settings({
    //   ssl: true,
    //   host: "cors-anywhere.herokuapp.com/firestore.googleapis.com"
    // });
    // console.log("fs", firebase.firestore().Settings);
    // load data from cloud
    const col = firebase.firestore().collection("wecheck");

    col
      .doc("reserved-data")
      .get()
      .then(doc => {
        const reserved_data = doc.data();

        col
          .doc("user_data")
          .get()
          .then(doc => {
            const user_data = doc.data();
            Object.keys(reserved_data.items).map(k => {
              data.items[k] = {
                ticked: false,
                text: k,
                amount: reserved_data.items[k]
              };
            });
            data.score = 0;
            console.log("load reserved-data", data);

            if (data.uuid in user_data) {
              console.log("y");
              data = user_data[data.uuid];
              Object.keys(reserved_data.items).map(k => {
                data.items[k].amount = reserved_data.items[k];
              });
              data.score = reserved_data.user_score[data.uuid];
            } else {
              console.log("n");
            }
            localStorage.setItem("wecheck-data", JSON.stringify(data));
            console.log("save data to local", data);
            col.doc("user_data").update({ [data.uuid]: data });
            console.log("update data in cloud");
          });
      });
  });
</script>

<style lang="scss">
  .root {
    user-select: none;
    -webkit-user-select: none;
    background: #fafafa;
    height: 100%;
  }
  h2 {
    color: white;
    text-shadow: 0 0 10px #ff2800, 0 0 12px #ff2800;
    font-size: 2rem;
    margin: 2rem 3%;
  }
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    // --color: #ff92bf;
    --color: #f5918c;

    > div {
      position: relative;
      width: 92%;
      display: grid;
      grid-template-columns: 1fr 20fr 1fr;
      // justify-content: space-between;
      align-items: center;
      font-size: 1.16rem;
      border-radius: 10px;
      margin: 0.5rem;
      padding: 0.65rem 0.3rem;
      // background: #fff0f0;
      // box-shadow: 1px 6px 6px #fff0f0;
      border: 2px #ffe0e0 solid;
      // box-shadow: 1px 6px 6px #3334;
      transition: all 0.3s ease-out;
      &:active {
        transition: all 0.1s ease-in;
        transform: translate(0, 3px);
      }
      &:nth-child(1) {
        border-color: #f5918c;
        // background: #f5918c;
        // box-shadow: 1px 6px 6px #ff6060;
        .square {
          background: #f5918c;
        }
      }
      &:nth-child(2) {
        border-color: #fab0ae;
        // background: #fab0ae;
        // box-shadow: 1px 6px 6px #ff8080;
        .square {
          background: #fab0ae;
        }
      }
      &:nth-child(3) {
        border-color: #fdd0d0;
        // background: #fdd0d0;
        // box-shadow: 1px 6px 6px #ffa0a0;
        .square {
          background: #fdd0d0;
        }
      }
      &:nth-child(4) {
        border-color: #fee0e0;
        // background: #fee0e0;
        // box-shadow: 1px 6px 6px #ffc0c0;
        .square {
          background: #fee0e0;
        }
      }
    }
  }
  .square {
    background: #fff0f0;
    width: 2rem;
    height: 2rem;
    border-radius: 6px;
    margin: 0 0.5rem;
  }
  .counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.4rem;
    width: 2rem;
    height: 1.2rem;
    transform: translateY(-0.3rem);
    span {
      margin-left: 0.1rem;
    }
  }
  .text {
    // justify-self: center;
    width: fit-content;
    // width: min-content;
    padding: 0 0.6rem;
    background-repeat: no-repeat;
    background-size: 30% 1.5px, calc(100% - 20px) 10px, calc(100% - 20px) 10px,
      10px 10px, 8px 8px;
    background-position: 100% calc(50% + 2px), center center, center center,
      2px 50%, calc(100% - 3px) calc(50% + 1px);
  }
  .ticked {
    background-image: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.5),
        transparent
      ),
      linear-gradient(2deg, var(--color) 50%, transparent 50%),
      linear-gradient(-0.9deg, var(--color) 50%, transparent 50%),
      linear-gradient(-60deg, var(--color) 50%, transparent 50%),
      linear-gradient(120deg, var(--color) 50%, transparent 50%);
  }
  .mask {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 150px;
    pointer-events: none;
    width: 100%;
    background: linear-gradient(0deg, white, #fff0);
  }
  .footer-wrapper {
    margin: 5rem 0;
  }
</style>

<svelte:head>
  <title>共享打卡</title>
</svelte:head>
<div class="root">
  <h2>Jellow假期小挑战</h2>
  <!-- <div>之假期小挑战</div> -->
  <form class="list">
    {#each itemList as item, index}
      <div
        on:click={() => {
          const col = firebase.firestore().collection('wecheck');
          item.ticked = !item.ticked;
          console.log('waiting to upload change');
          col.doc('user_data').update({ [data.uuid]: data });
          col
            .doc('reserved-data')
            .update({
              [`user_score.${data.uuid}`]: itemList.reduce(
                (acc, cur) => acc + (cur.ticked ? 1 : 0),
                0
              )
            });
          if (item.ticked) {
            col
              .doc('reserved-data')
              .update({ [`items.${item.text}`]: increment });
            item.amount += 1;
            data.score += 1;
          } else {
            col
              .doc('reserved-data')
              .update({ [`items.${item.text}`]: decrement });
            item.amount -= 1;
            data.score -= 1;
          }
          console.log('update data with server');
          const tmp = JSON.stringify(data);
          localStorage.setItem('wecheck-data', tmp);
        }}>
        <div class="square" />
        <span class="text" class:ticked={item.ticked}>{item.text}</span>
        <span class="counter">
          <ICON name="people" flex={true} fill="#fdd0d0" />
          <span>{item.amount}</span>
        </span>
      </div>
    {/each}
  </form>
  <div class="footer-wrapper">
    <OaFooter />
  </div>
  <div class="mask" />
</div>
