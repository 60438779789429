<script>
  import { onMount } from 'svelte'
  import { map } from './helpers.svelte'
  import ICON from './icons.svelte'
  import ImgSaver from './ImgSaver.svelte'
  // import Perlin from "./pl.js";
  import { Perlin } from './perlin'

  let _mode = 'arc'
  let perlinMap = [] // pre calc
  let perlinMapSize = 256,
    repeatTimePerIteration = 50
  let drawingMode = {
    allModes: ['arc', 'line', 'square'],
    index: 0,
    current: 'arc',
    next() {
      this.index++
      if (this.index >= drawingMode.allModes.length) this.index = 0
      this.current = this.allModes[this.index]
      if (this.current === 'arc') {
        repeatTimePerIteration = 50
      } else if (this.current === 'line') {
        repeatTimePerIteration = 30
      } else if (this.current === 'square') {
        repeatTimePerIteration = 20
      }
    },
    set(mode) {
      this.current = mode
      _mode = mode
    },
  }

  // let perlin = new Perlin();
  let pl = new Perlin(Math.random())
  let ctx,
    canva,
    strokeW = 18,
    r = 10,
    drawingLimit = 300,
    drawingCount = 0,
    width = 864,
    height = 864,
    input,
    imgW,
    imgH,
    margin = 30,
    imgData
  let newH = 0,
    newW = 0,
    newX,
    newY,
    bgClr = '#fff'
  let imgUrl = '',
    isSaving = false

  function loadImg() {
    if (input.files && input.files[0]) {
      const tmpImg = new Image()
      tmpImg.onload = () => {
        imgLoaded(tmpImg)
      }
      tmpImg.src = URL.createObjectURL(input.files[0])
    }
  }
  function imgLoaded(img) {
    if (img.height > img.width) {
      newH = height - margin * 2
      newW = (img.width * height) / img.height - margin * 2
    } else {
      newW = width - margin * 2
      newH = (img.height * width) / img.width - margin * 2
    }
    newX = (width - newW) / 2
    newY = (height - newH) / 2
    // newW -= 10;
    // newH -= 10;
    ctx.fillStyle = bgClr
    ctx.fillRect(0, 0, width, height)
    ctx.drawImage(img, newX, newY, newW, newH)
    imgData = ctx.getImageData(newX, newY, newW, newH)
    // console.log(imgData, "data");

    ctx.fillStyle = bgClr
    ctx.fillRect(0, 0, width, height)
    drawAgain()
  }
  function getPixel(imgdata, x, y) {
    const baseIndex = (y - 1) * 4 * imgdata.width + (x - 1) * 4
    return {
      r: imgdata.data[baseIndex],
      g: imgdata.data[baseIndex + 1],
      b: imgdata.data[baseIndex + 2],
      a: imgdata.data[baseIndex + 3],
    }
  }
  // function switchDrawingMode(mode) {
  //   switch (mode) {
  //     case "arc":
  //       drawingMode.set("arc");
  //       break;
  //     case "line":
  //       repeatTimePerIteration = 25;
  //       drawingMode.set("line");
  //       break;
  //       // case 'square':
  //     default:
  //       break;
  //   }
  // }
  function draw() {
    if (!imgData) return
    let startAng = Math.random() * Math.PI * 2
    let endAng = Math.random() * Math.PI * 2
    if (drawingCount < drawingLimit / 4) {
      startAng = 0
      endAng = Math.PI * 2
    }
    ctx.lineWidth = map(drawingCount, 0, drawingLimit, strokeW, 1)
    for (let i = 0; i < repeatTimePerIteration; i++) {
      const x = 2 + Math.floor(Math.random() * (newW - 2)), //2px bleeding for safari, strange bug
        y = 1 + Math.floor(Math.random() * newH)
      const pixel = getPixel(imgData, x, y)
      ctx.fillStyle =
        ctx.strokeStyle = `rgba(${pixel.r},${pixel.g},${pixel.b},1)`

      ctx.save()
      ctx.beginPath()
      ctx.translate(newX + x, newY + y)
      const a =
        perlinMap[Math.floor((x * perlinMapSize) / newW)][
          Math.floor((y * perlinMapSize) / newH)
        ]
      ctx.rotate(a)
      if (drawingMode.current === 'arc') {
        ctx.arc(0, 0, r, startAng, endAng)
      } else if (drawingMode.current === 'line') {
        ctx.moveTo(2 * Math.min(-ctx.lineWidth, -5), 0)
        ctx.lineTo(2 * Math.max(5, ctx.lineWidth), 0)
        ctx.fillStyle = ctx.strokeStyle = `rgba(${pixel.r},${pixel.g},${
          pixel.b
        },${0.78})`
        ctx.lineWidth -= 4
        ctx.moveTo(1.5 * Math.min(-ctx.lineWidth, -5), 3)
        ctx.lineTo(1.8 * Math.max(5, ctx.lineWidth), 3)
      } else if (drawingMode.current === 'square') {
        ctx.strokeRect(-2 * r, -2 * r, 2 * r, 2 * r)
      }
      ctx.stroke()
      ctx.restore()
    }
    // ctx.fillRect(0, 0, 55, 55);
    if (drawingCount >= drawingLimit) return
    drawingCount++
    window.requestAnimationFrame(draw)
  }
  function drawAgain() {
    //bake perlinMap
    // perlin.seed();
    pl = new Perlin(Math.random())

    for (let i = 0; i < perlinMapSize; i++) {
      perlinMap[i] = []
      for (let j = 0; j < perlinMapSize; j++) {
        perlinMap[i][j] = pl.noise(i / perlinMapSize, j / perlinMapSize, 0) * 20
        // perlinMap[i][j] = perlin.get(i / 128, j / 128) * 20;
      }
    }
    // console.log(perlinMap);
    drawingCount = 0
    ctx.fillStyle = bgClr
    ctx.fillRect(0, 0, width, height)
    draw()
  }
  onMount(() => {
    window.scrollTo({ top: 0 })
    // width = window.innerWidth;
    // height = width;
    canva.width = width
    canva.height = height
    ctx = canva.getContext('2d')
    ctx.fillStyle = bgClr
    ctx.fillRect(0, 0, width, height)
    // ctx.fillRect();
    const img = new Image()
    img.onload = () => {
      imgLoaded(img)
    }
    img.src = 'assets/maid.png'
  })
</script>

<svelte:head>
  <title>烫头</title>
</svelte:head>
<ImgSaver bind:isSaving dataUrl={imgUrl} />
<main>
  <canvas bind:this={canva} />
  <div class="controls">
    <button
      class="btn-save"
      on:click={() => {
        imgUrl = canva.toDataURL('image/png')
        isSaving = true
      }}
    >
      <ICON name="save" stroke="#333" />
    </button>
    <button class="btn-upload">
      <input
        bind:this={input}
        type="file"
        on:change={loadImg}
        accept="image/png,image/jpeg,image/jpg"
        id="upload"
      />
      <label for="upload">选择图片</label>
    </button>
    <button
      class="btn-bg"
      on:click={e => {
        e.preventDefault()
        bgClr = bgClr === '#333' ? '#fff' : '#333'
      }}
      style={`background:${bgClr};color:${bgClr === '#333' ? '#fff' : '#333'};`}
    >
      背景
    </button>
    <button
      class="btn-gen"
      on:click={e => {
        e.preventDefault()
        drawAgain()
      }}
    >
      烫头
    </button>
    <button
      class="btn-sw"
      on:click={e => {
        e.preventDefault()
        strokeW -= 4
        if (strokeW <= 0) {
          strokeW = 18
        }
      }}
    >
      <span>{strokeW}</span>
      <div style={`height:${strokeW}px;`} />
    </button>
    <button
      class="btn-r"
      on:click={e => {
        e.preventDefault()
        r -= 2
        if (r <= 0) {
          r = 20
        }
      }}
    >
      <span>{r}</span>
      <div
        class={_mode}
        style={`border:solid ${strokeW}px #d4ae3f;width:${r * 2}px;height:${
          r * 2
        }px;`}
      />
    </button>
    <button
      class="btn-other"
      on:click={() => {
        drawingMode.next()
        _mode = drawingMode.current
      }}
    >
      施工区域
      <br />
      暂未开放
    </button>
  </div>
</main>

<style lang="scss">
  // main {
  //   // position: fixed;
  //   // height: 120vh;
  //   // width: 90vw;
  //   display: flex;
  //   flex-direction: column;
  //   background: white;
  //   overflow-y: scroll;
  //   user-select: none;
  // }
  canvas {
    // user-select: none;
    width: 100vw;
  }
  input {
    display: none;
  }
  .controls {
    display: grid;
    height: calc(100% - 100vw);
    grid-template:
      'save up up up' 20vw
      'r r w w ' 20vw
      'r r bg gen ' 20vw
      'other other other other' 1fr
      / 20vw 20vw 1fr 1fr;
    border: solid 4px #333;
    margin: 10px;
    padding: 20px;
    button {
      // user-select: none;
      width: 100%;
      height: 100%;
      border-radius: 0;
      font-weight: bold;
    }
  }
  .btn-save {
    border-width: 0 0 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: save;
    background: #f2edea;
    // color: white;
    border-color: #333;
    border-style: solid;
  }
  .btn-upload {
    grid-area: up;
    background-color: #674287;
    color: #d6a9f0;
    font-weight: bold;
    font-size: 1.4rem;
    border-width: 0 0 4px 4px;
    border-color: #000;
    border-style: solid;
  }
  .btn-bg {
    border-width: 0 4px 0 0;
    border-color: #000;
    border-style: solid;
    grid-area: bg;
  }
  .btn-other {
    grid-area: other;
    background: #e1d8c5;
    border-width: 4px 0 0 0;
    border-color: #000;
    border-style: solid;
    // height: min-content;
  }
  .btn-sw {
    border-width: 0 0 4px 0;
    border-color: #000;
    border-style: solid;
    background-color: #e1553a;
    grid-area: w;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    span {
      position: absolute;
      left: 5px;
      top: 5px;
    }
    div {
      border-radius: 5px;
      width: 70%;
      background: wheat;
      border: none;
    }
  }
  .btn-gen {
    grid-area: gen;
    border: none;
    background: #f2edea;
  }
  .btn-r {
    border-width: 0 4px 0 0;
    border-color: #000;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: r;
    background: #fbd36a;
    position: relative;
    span {
      position: absolute;
      left: 5px;
      top: 5px;
    }
    div {
      &.arc {
        border-radius: 50%;
        background: transparent;
      }
      &.square {
        border-radius: 3px;
      }
      &.line {
        visibility: hidden;
        &:after {
          visibility: visible;
          content: '隐藏模式';
          // width: 30px;
          // height: 5px;
          // border-radius: 3px;
        }
      }
    }
  }
</style>
