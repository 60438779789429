<script>
  let step = 1
  let max = 240
  let blockW = '40px'
  let rotate = false
</script>

<svelte:head>
  <title>看看你的👀</title>
</svelte:head>
<main
  style:--max={max}
  style:--block-w={blockW}
  style:--anim={rotate ? 'rotate' : 'slide'}
  on:click={() => (rotate = !rotate)}
>
  {#each Array(max / step) as _, idx}
    {@const hz = (idx + 1) * step}
    <div
      class="wrapper"
      class:invert={hz / max > 0.75}
      class:rotate
      style:--hz={hz}
      style="height:{rotate ? 40 : 20}px;"
    >
      <div class="text" style:--duration="{1000 / hz}ms">{hz}Hz</div>
    </div>
  {/each}
</main>

<style>
  :global(body) {
    margin: 0;
    padding: 8px;
  }
  main {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(var(--block-w), 1fr));
    flex-wrap: wrap;
    gap: 6px;
  }
  .wrapper {
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 2px;
    border-radius: 2px;
    position: relative;
    background: rgba(33, 78, 9, calc(var(--hz) / var(--max)));
  }
  .rotate {
    border-radius: 99px;
  }
  .text {
    color: white;
    text-align: center;
    font-size: 10px;
    animation: slide linear var(--duration) infinite;
  }

  @keyframes slide {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(20px);
    }
  }

  @keyframes rotate {
    from {
      rotate: 0deg;
    }
    to {
      rotate: 360deg;
    }
  }
</style>
