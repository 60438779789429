<script>
  import { onMount } from "svelte";
  import QRCode from "./qr";
  import { isCN, lang } from "./lang/strings";

  let cbTxt = "";
  let imgUrl = "";
  let timer;
  let noInput = true;
  // onMount(async () => {
  //   cbTxt = await navigator.clipboard.readText();
  //   cbTxt && QRCode.toDataURL(cbTxt, { margin: 4 }).then(u => (imgUrl = u));
  // });
</script>

<style lang="scss">
  main {
    width: 100%;
    min-height: 100vh;
    padding: 5%;
    > * {
      width: 100%;
    }
  }
  textarea {
    border: solid 2px;
    outline: none;
    border-radius: 10px;
  }
  button {
    border-radius: 10px;
    height: 50px;
    font-size: 1.2rem;
  }
  figure {
    text-align: center;
    // text-transform: italic;
    margin: 0;
  }
  .hidden {
    visibility: hidden;
  }
</style>

<main>
  <textarea
    on:input={evt => {
      const txt = evt.target.value;
      clearTimeout(timer);
      timer = setTimeout(() => QRCode.toDataURL(txt, {
            margin: 4
          }).then(u => (imgUrl = u)), 1200);
      noInput = txt.length == 0;
    }}
    name=""
    id=""
    cols="30"
    rows="10" />
  <!-- <p>
    {isCN ? '你知道吗?任何软件随时都能悄无声息地读取你的剪切板内容，包括本网站。请对这样的行为说「不」。' : 'Do you know that every App could read your clipboard silently, including this website. Speak out to blame such behaviours.'}
  </p> -->
  <!-- <button on:click={()=>{

  }}>{lang.qc.btnGen}</button> -->
  <button
    on:click={async () => {
      cbTxt = await navigator.clipboard.readText();
      QRCode.toDataURL(cbTxt, { margin: 4 }).then(u => (imgUrl = u));
    }}>
    {lang.qc.btnCBGen}
  </button>
  <figure>
    <img src={imgUrl} alt="qrcode" class:hidden={!cbTxt && noInput} />
    <figcaption>
      {cbTxt ? (isCN ? '长按二维码以保存' : 'Press on the Code to save') : isCN ? '尚未获取剪切板文字' : "Haven't got text from clipboard yet"}
    </figcaption>
  </figure>

</main>
