<script>
</script>

<iframe
  title="chiahouse"
  id="ChiaHouse"
  src="https://las-pinas.com/h.html?i=gnimoay"
  on:load={e => {
    const o = e.target
    o.style.height = `${o.contentWindow.document.body.scrollHeight}px`
  }}
  style="width:100vw; min-height: 600px;border:none;overflow:scroll;"
  houseid="gnimoay"
></iframe>

<style lang="scss">
  #ChiaHouse {
    // left: 0;
    // top: 0;
    // position: fixed;
    grid-column: 1/-1;
  }
</style>
