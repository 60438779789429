<script>
  import Router from 'svelte-spa-router'
  import JikeMsg from './jk-msg/JikeMsg.svelte'
  import Home from './Home.svelte'
  import SquareDemo from './square/Square.svelte'

  import WeCheck from './wecheck/check.svelte'
  import LowCheck from './wecheck/StaticCheck.svelte'
  import Intranator from './intranator/Intranator.svelte'
  import ChinChinois from './chinchinois/ChinChinois.svelte'
  import QC from './QC.svelte'
  import TT from './tangtou.svelte'
  import CardCase from './card-case/CardCase.svelte'
  import FreshRate from './FreshRate.svelte'

  const routes = {
    // Exact path
    '/card': CardCase,
    '/check': WeCheck,
    '/chinchinois': ChinChinois,
    '/intranator': Intranator,
    '/jk-msg': JikeMsg,
    '/qc': QC,
    '/square': SquareDemo,
    '/uncheck': LowCheck,
    '/tangtou': TT,
    '/fresh-rate': FreshRate,
    // Using named parameters, with last being optional
    //'/author/:first/:last?': Author,
    // Wildcard parameter
    //'/book/*': Book,

    // Catch-all
    // This is optional, but if present it must be the last
    '*': Home,
  }
</script>

<Router {routes} />
