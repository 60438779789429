<script>
  export let style = "";
</script>

<style lang="scss">
  footer {
    // text-align: start;
    justify-content: center;
    padding: 0 12px;
    display: flex;
    height: var(--footerH);
    align-items: center;
    a {
      color: var(--clrLink);
      text-decoration: none;
      margin-left: 0.4rem;
    }
  }
</style>

<footer {style}>
  {'Built with 🧡 by '}
  <a href="https://gnimoay.com" target="_blank">
    <span>@GnimOay</span>
  </a>
</footer>
