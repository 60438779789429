<script>
  import { lang } from '../lang/strings'
  import { copy } from '../helpers.svelte'

  let ta, txt
  let rawTxt = '',
    resultTxt = '',
    _gap = 0,
    _connector = '',
    connectorLevel = 0,
    showNotification = false

  function txtSwitch(txt, placeholder, connector = '   ') {
    let lines = txt.split('\n')
    lines.forEach(l => (l = Array.from(l)))
    const rows = lines.length,
      cols = lines.reduce(
        (num, cur) => (cur.length > num ? cur.length : num),

        0
      )
    let newLines = Array.from(Array(cols)).map(a =>
      Array.from(Array(rows)).map(a => placeholder)
    )
    for (let oldR = 0; oldR < rows; oldR++) {
      for (let oldC = 0; oldC < lines[oldR].length; oldC++) {
        const newR = oldC,
          newC = rows - 1 - oldR
        newLines[newR][newC] = lines[oldR][oldC]
      }
    }
    return newLines.map(line => line.join(connector)).join('\n')
    // .replace(/[ ]\||\|[ ]/, "K");
  }
  function calcTxt(value) {
    resultTxt = txtSwitch(value, '    ', _connector)
    // console.log(resultTxt);
    txt.innerHTML = resultTxt.replace(/[ ]/g, '&nbsp;').replace(/\n/g, '<br/>')
    // txtSwitch(
    //   value,
    //   "&nbsp;&nbsp;&nbsp;&nbsp;",
    //   _connector
    // ).replace(/\n/g, "<br/>");
  }
</script>

<svelte:head>
  <title>{lang.chinchinois.title}</title>
</svelte:head>
<div class="root">
  <!-- <div class="p1"> -->
  <div class="heading">讲新话,树新风</div>
  <textarea
    on:input={evt => {
      rawTxt = evt.target.value
      calcTxt(rawTxt)
    }}
    bind:this={ta}
    textarea
    class="p1"
    cols="40"
    rows="10"
  />
  <div class="tools">
    <!-- <span>
      新话等级
      <span>level</span>
    </span> -->
    <button
      on:click={() => {
        resultTxt = ''
        txt && (txt.innerHTML = '')
        ta.value = ''
      }}
    >
      {lang.chinchinois.btnClear}
    </button>
    <button
      on:click={() => {
        connectorLevel++
        connectorLevel = connectorLevel > 3 ? 0 : connectorLevel
        _connector = { 0: '', 1: '.', 2: '   ', 3: '      ' }[connectorLevel]
        calcTxt(rawTxt)
      }}
    >
      {lang.chinchinois.btnAssist}:
      <span>{connectorLevel}</span>
    </button>
    <button
      on:click={() => {
        if (!resultTxt) return
        copy(resultTxt)
        showNotification = true
        setTimeout(() => {
          showNotification = false
        }, 2000)
      }}
    >
      {lang.chinchinois.btnRemember}
    </button>
  </div>
  <!-- </div> -->
  <!-- <div class="p2"> -->
  <div class="txt" class:border={resultTxt} bind:this={txt} />
  <!-- </div> -->
  <ul class="brief">
    <li>练习新话时请确保身边没有别人</li>
    <li>新话的最佳学习方式是「{lang.chinchinois.btnRemember}」</li>
    <li>练习完毕后别忘了「{lang.chinchinois.btnClear}」</li>
    <li>句子长度统一的,是更好的新话</li>
    <li>高级新话尚处于研究阶段</li>
  </ul>
  <div class="researcher">
    <div>
      <span>其实...我希望.....高级新话永远也研究不出来.</span>
    </div>
    <figure>
      <img src="assets/researcher.png" alt="" />
      <figcaption>{lang.chinchinois.researcher}</figcaption>
    </figure>
  </div>
  <div class:visible={showNotification} class="hidden notify">
    已复制到剪切板
  </div>
</div>

<style lang="scss">
  .root {
    // overflow: scroll;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: white;
  }
  .heading {
    border: 3px solid;
    border-radius: 10px;
    padding: 5px 10px;
    color: #333;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 2.5rem 0 1rem;
  }
  textarea {
    width: 90%;
    // margin-left: 5vw;
    border-radius: 10px;
    outline: none;
    margin: 1rem 0 2rem;
    // border:none;
    border: dashed 3px #333;
  }

  // .p1,
  // .p2 {
  //   max-height: 80%;
  //   height: 100%;
  //   overflow-y: scroll;
  // }
  .tools {
    // position: sticky;
    // top: 0;
    // bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .txt {
    &.border {
      visibility: visible;
      border: solid 3px #333;
    }
    visibility: hidden;
    margin: 2rem 0 3rem;
    border-radius: 10px;
    // width: 90%;
    padding: 8px 12px;
    width: fit-content;
    outline: none;
  }
  .brief {
    writing-mode: vertical-rl;
    line-height: 2rem;
  }
  .researcher {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // position: relative;
    figure {
      width: 60%;
      // margin: 2rem 0;
      img {
        width: 100%;
      }
      figcaption {
        text-align: center;
        font-size: 1.2rem;
        margin-top: -1rem;
      }
    }
    div {
      border: 3px solid;
      width: fit-content;
      padding: 1rem 1.3rem;
      border-radius: 10px;
      width: 60%;
      margin-left: 20%;
      margin-top: 8rem;
      font-size: 1.2rem;
      z-index: 99;
      background: white;
      position: relative;
      &:after {
        position: absolute;
        left: 2rem;
        content: '';
        transform: rotate(45deg);
        border-radius: 4px;
        background: #333;
        z-index: 98;
        bottom: -11px;
        width: 20px;
        height: 20px;
      }
      & > span {
        &:before,
        &:after {
          content: '"';
          font-size: 3rem;
        }
      }
    }
  }
  .hidden {
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: all 0.3s ease-in;
  }
  .notify {
    background: #333;
    color: white;
    position: sticky;
    // left: calc(50vw - 4rem);
    font-size: 1.1rem;
    padding: 1rem;
    border-radius: 10px;
    bottom: 30px;
  }
  .visible {
    visibility: visible;
    opacity: 1;
  }
  button {
    margin: 0 3px;
    border-radius: 4px;
    padding: auto 2px;
    background: rgb(20, 42, 80);
    background: linear-gradient(
      293deg,
      rgba(20, 42, 80, 1) 0%,
      rgba(0, 6, 16, 1) 100%
    );
    color: white;
    text-shadow: 0 0 5px #30c1f1;
    clip-path: polygon(
      0 0,
      100% 0%,
      100% calc(100% - 11px),
      calc(100% - 13px) 100%,
      0 100%
    );
  }
</style>
