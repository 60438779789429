<script>
  import Zdog from "zdog";
  import { onMount } from "svelte";

  export let width = 240;
  export let height = 240;

  onMount(() => {
    const tau = Zdog.TAU;
    let illo = new Zdog.Illustration({
      element: ".ufo-canvas",
      dragRotate: false,
      scale: 0.9,
      rotate: { x: -tau / 20 }
    });
    let origin = new Zdog.Anchor({
      addTo: illo,
      rotate: { x: tau / 4 },
      translate: { y: -15 }
    });

    // body----------
    let body = new Zdog.Group({
      addTo: origin
    });
    let ring = new Zdog.Ellipse({
      addTo: body,
      diameter: 200,
      // length: 90,
      stroke: 30,
      color: "#334"
    });
    let circle = new Zdog.Cylinder({
      addTo: body,
      diameter: 180,
      length: 20,
      color: "#334"
    });

    // topHemi
    let dome = new Zdog.Hemisphere({
      addTo: body,
      diameter: 120,
      // fill enabled by default
      // disable stroke for crisp edge
      stroke: false,
      color: "#385",
      backface: "#EA0"
    });

    let dia = 140;
    let diaOffset = 1;
    // light beam
    let beam = new Zdog.Cone({
      addTo: origin,
      diameter: dia,
      translate: { z: -68 },
      length: 130,
      stroke: false,
      // fill:false,
      color: "#668",
      backface: false
    });

    // dots
    const dotDist = 100;
    let dot = new Zdog.Shape({
      addTo: circle,
      color: "#fafafa",
      stroke: 22,
      translate: { x: dotDist }
    });
    dot.copyGraph({
      translate: { x: -dotDist }
    });
    dot.copyGraph({
      translate: { y: dotDist }
    });
    dot.copyGraph({
      translate: { y: -dotDist }
    });

    let step = 0.023;
    function animate() {
      illo.rotate.y += 0.02;
      illo.rotate.z += step;
      if (illo.rotate.z >= tau / 60 || illo.rotate.z <= -tau / 60) {
        step = -step;
      }
      illo.updateRenderGraph();
      requestAnimationFrame(animate);
    }
    animate();
  });
</script>

<style>
  .ufo-canvas {
    width: 95% !important;
    height: auto !important;
    background: #241b2f;
    border-radius: 12px;
  }
</style>

<canvas class="ufo-canvas" {width} {height} />
