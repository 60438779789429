<script>
  export let name = ''
  export let fill = 'none'
  export let stroke = 'currentColor'
  export let bg = false
  export let className = ''
  export let width = 48
  export let height = 48
  export let autoHW = false
</script>

<!--
   //// Options ////////////
   switch
jstore
pingpal
orange
comeet
universe
jellow
jike
kuai
yiguan
shengchen

  ////////////  ////
 -->

<div
  class={'icon ' + name + ' ' + className}
  style={autoHW
    ? 'width:100%;height:100%;'
    : `
width:${width}px;
height:${height}px;
`}
>
  {#if name === 'jstore'}
    <svg
      width="51"
      height="50"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.67799 33.26C7.4392 32.6863 7.39609 32.0499 7.55534 31.4492C7.7146
        30.8486 8.06733 30.3171 8.55899 29.937C8.29993 29.2429 8.25621 28.4869
        8.43354 27.7676C8.61087 27.0483 9.00101 26.3992 9.55299 25.905C9.55299
        25.749 9.55299 25.535 9.55299 25.28C9.55299 24.38 9.575 24.289 9.638
        24.23C9.946 23.942 11.068 24.688 11.313 24.854C12.2614 24.617 13.2536
        24.617 14.202 24.854C14.447 24.688 15.569 23.942 15.877 24.23C15.94
        24.289 15.958 24.381 15.962 25.28C15.962 25.535 15.962 25.749 15.962
        25.905C16.514 26.3992 16.9041 27.0483 17.0814 27.7676C17.2588 28.4869
        17.2151 29.2429 16.956 29.937C17.4478 30.317 17.8005 30.8485 17.9596
        31.4492C18.1187 32.05 18.0752 32.6865 17.836 33.26"
        fill="white"
      />
      <path
        d="M3 33.26H49.917V39.46C49.9173 40.8096 49.6517 42.1461 49.1354
        43.3931C48.6191 44.6401 47.8622 45.7731 46.9079 46.7275C45.9537 47.682
        44.8208 48.4391 43.5739 48.9556C42.327 49.4721 40.9906 49.738 39.641
        49.738H13.426C10.6609 49.738 8.00896 48.6396 6.0537 46.6843C4.09845
        44.7291 3 42.0772 3 39.312L3 33.26Z"
        fill="#8BA9B3"
      />
      <path d="M49.917 33.26H3V35.417H49.917V33.26Z" fill="#0A3C48" />
      <path
        d="M7.067 20.677C6.02589 20.6955 5.01859 20.3074 4.25915 19.595C3.49971
        18.8826 3.04799 17.9022 3 16.862H10.885C10.8411 17.8602 10.4247 18.8058
        9.71792 19.512C9.01111 20.2183 8.06525 20.6339 7.067 20.677V20.677Z"
        fill="#FFCA39"
      />
      <path
        d="M10.882 3V16.861H3V10.882C3 8.79156 3.83042 6.78675 5.30858
        5.30858C6.78675 3.83042 8.79156 3 10.882 3V3Z"
        fill="#FFCA39"
      />
      <path
        d="M22.831 20.677C21.7899 20.6955 20.7826 20.3074 20.0232 19.595C19.2637
        18.8826 18.812 17.9022 18.764 16.862H26.646C26.6009 17.8593 26.1844
        18.8036 25.4785 19.5095C24.7726 20.2154 23.8283 20.6319 22.831 20.677Z"
        fill="#FFCA39"
      />
      <path d="M26.646 3H18.764V16.861H26.646V3Z" fill="#FFCA39" />
      <path
        d="M38.594 20.677C37.5531 20.6953 36.546 20.307 35.7868 19.5947C35.0276
        18.8823 34.576 17.902 34.528 16.862H42.409C42.3652 17.8597 41.9492
        18.8049 41.243 19.511C40.5369 20.2172 39.5917 20.6332 38.594
        20.677V20.677Z"
        fill="#FFCA39"
      />
      <path d="M42.41 3H34.528V16.861H42.41V3Z" fill="#FFCA39" />
      <path
        d="M14.949 20.677C13.9079 20.6955 12.9006 20.3074 12.1411 19.595C11.3817
        18.8826 10.93 17.9022 10.882 16.862H18.764C18.7202 17.8597 18.3042
        18.8049 17.598 19.511C16.8918 20.2172 15.9467 20.6332 14.949
        20.677V20.677Z"
        fill="#FF6F39"
      />
      <path d="M18.764 3H10.882V16.861H18.764V3Z" fill="#FF6F39" />
      <path
        d="M30.713 20.677C29.6719 20.6955 28.6646 20.3074 27.9052 19.595C27.1457
        18.8826 26.694 17.9022 26.646 16.862H34.528C34.4829 17.8593 34.0664
        18.8036 33.3605 19.5095C32.6546 20.2154 31.7103 20.6319 30.713 20.677Z"
        fill="#FF6F39"
      />
      <path d="M34.528 3H26.646V16.861H34.528V3Z" fill="#FF6F39" />
      <path
        d="M46.476 20.677C45.4349 20.6955 44.4276 20.3074 43.6682 19.595C42.9087
        18.8826 42.457 17.9022 42.409 16.862H50.291C50.2472 17.8597 49.8312
        18.8049 49.125 19.511C48.4188 20.2172 47.4737 20.6332 46.476
        20.677V20.677Z"
        fill="#FF6F39"
      />
      <path
        d="M42.409 3C44.4994 3 46.5043 3.83042 47.9824 5.30858C49.4606 6.78675
        50.291 8.79156 50.291 10.882V16.862H42.409V3Z"
        fill="#FF6F39"
      />
      <path
        d="M15.95 29.414C15.7842 28.8285 15.436 28.3111 14.956 27.937C15.2151
        27.2429 15.2588 26.4869 15.0815 25.7676C14.9041 25.0483 14.514 24.3992
        13.962 23.905C13.962 23.749 13.962 23.535 13.962 23.28C13.962 22.38
        13.94 22.289 13.877 22.23C13.569 21.942 12.447 22.688 12.202
        22.854C11.2536 22.617 10.2614 22.617 9.313 22.854C9.068 22.688 7.946
        21.942 7.638 22.23C7.575 22.289 7.557 22.381 7.553 23.28C7.553 23.535
        7.553 23.749 7.553 23.905C7.00101 24.3992 6.61088 25.0483 6.43355
        25.7676C6.25622 26.4869 6.29994 27.2429 6.559 27.937C6.06734 28.3171
        5.7146 28.8486 5.55535 29.4492C5.3961 30.0499 5.4392 30.6863 5.678
        31.26H1V37.312C1 38.6813 1.26971 40.0371 1.79373 41.3021C2.31775 42.5671
        3.08581 43.7165 4.05406 44.6846C5.02231 45.6528 6.17178 46.4208 7.43684
        46.9447C8.70189 47.4685 10.0578 47.7381 11.427 47.738H37.642C40.3675
        47.7377 42.9812 46.6549 44.9083 44.7276C46.8354 42.8003 47.918 40.1865
        47.918 37.461V31.261H15.836C16.0773 30.6749 16.1174 30.0253 15.95
        29.414V29.414Z"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40.409 1H8.88499C6.79455 1 4.78974 1.83043 3.31158 3.30859C1.83342
        4.78676 1.00299 6.79158 1.00299 8.88202V14.862C1.0356 15.8855 1.46511
        16.8561 2.20063 17.5686C2.93615 18.2811 3.91999 18.6794 4.94399
        18.6794C5.968 18.6794 6.95184 18.2811 7.68736 17.5686C8.42288 16.8561
        8.85238 15.8855 8.88499 14.862C8.9176 15.8855 9.34711 16.8561 10.0826
        17.5686C10.8181 18.2811 11.802 18.6794 12.826 18.6794C13.85 18.6794
        14.8338 18.2811 15.5694 17.5686C16.3049 16.8561 16.7344 15.8855 16.767
        14.862C16.7996 15.8855 17.2291 16.8561 17.9646 17.5686C18.7001 18.2811
        19.684 18.6794 20.708 18.6794C21.732 18.6794 22.7158 18.2811 23.4514
        17.5686C24.1869 16.8561 24.6164 15.8855 24.649 14.862C24.6816 15.8855
        25.1111 16.8561 25.8466 17.5686C26.5821 18.2811 27.566 18.6794 28.59
        18.6794C29.614 18.6794 30.5978 18.2811 31.3334 17.5686C32.0689 16.8561
        32.4984 15.8855 32.531 14.862C32.5674 15.8828 32.9984 16.8497 33.7335
        17.559C34.4685 18.2683 35.45 18.6646 36.4715 18.6646C37.4929 18.6646
        38.4745 18.2683 39.2095 17.559C39.9445 16.8497 40.3756 15.8828 40.412
        14.862C40.4446 15.8855 40.8741 16.8561 41.6096 17.5686C42.3451 18.2811
        43.329 18.6794 44.353 18.6794C45.377 18.6794 46.3608 18.2811 47.0964
        17.5686C47.8319 16.8561 48.2614 15.8855 48.294 14.862V8.88202C48.294
        7.84669 48.09 6.82147 47.6937 5.86499C47.2974 4.90851 46.7166 4.03945
        45.9843 3.3075C45.2521 2.57555 44.3829 1.99505 43.4262 1.59912C42.4696
        1.20319 41.4443 0.999607 40.409 1Z"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  {:else if name === 'pingpal'}
    <svg
      width="44"
      height="48"
      viewBox="0 0 44 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8044 17.665C29.4964 17.671 35.2844 22.919 36.4044 30.471C37.4364
        37.393 31.6994 47.912 21.1104 47.777C18.6963 47.7235 16.3332 47.0721
        14.2326 45.8811C12.1321 44.6901 10.3597 42.9966 9.07432 40.9525C7.78892
        38.9084 7.0306 36.5773 6.86726 34.1681C6.70392 31.759 7.14068 29.3469
        8.13841 27.148C10.1244 22.89 13.1114 19.72 17.6924 18.257C19.0174
        17.8187 20.4096 17.6182 21.8044 17.665Z"
        fill="#E7D230"
      />
      <path
        d="M14.9834 29.08C14.6444 28.767 14.3474 28.454 14.0094 28.193C13.7088
        27.9406 13.3324 27.7959 12.9402 27.7819C12.5479 27.768 12.1622 27.8855
        11.8444 28.116C10.7724 28.803 10.6854 29.609 11.3854 31.603C11.9104
        33.103 12.7134 33.874 13.8184 33.658C15.2551 33.3699 16.6699 32.9815
        18.0524 32.496C18.9274 32.196 19.1904 31.337 18.9524 30.181C18.8906
        29.7925 18.6879 29.4405 18.383 29.192C18.0781 28.9436 17.6923 28.8161
        17.2994 28.834C16.5514 28.788 15.8524 29.067 14.9834 29.08Z"
        fill="#E7D230"
      />
      <path
        d="M28.0714 31.109C28.0815 31.4205 28.1681 31.7247 28.3234
        31.9948C28.4788 32.2649 28.6983 32.4927 28.9624 32.658C29.5724 33.058
        30.1984 33.44 30.8274 33.813C31.0445 33.964 31.2893 34.0708 31.5477
        34.1272C31.8061 34.1836 32.0731 34.1886 32.3334 34.1418C32.5938 34.0951
        32.8423 33.9975 33.065 33.8547C33.2876 33.7119 33.4799 33.5266 33.6309
        33.3095C33.7819 33.0924 33.8887 32.8476 33.9451 32.5892C34.0016 32.3308
        34.0065 32.0638 33.9598 31.8035C33.913 31.5431 33.8155 31.2946 33.6726
        31.0719C33.5298 30.8493 33.3446 30.657 33.1274 30.506C32.393 29.9819
        31.5945 29.5541 30.7514 29.233C30.4498 29.1253 30.1267 29.0915 29.8093
        29.1343C29.4919 29.1771 29.1894 29.2953 28.927 29.479C28.6646 29.6627
        28.45 29.9065 28.3012 30.19C28.1523 30.4736 28.0735 30.7887 28.0714
        31.109V31.109Z"
        fill="#E7D230"
      />
      <path
        d="M29.5114 41.577C29.9264 41.6217 30.3444 41.5254 30.6981
        41.3038C31.0518 41.0821 31.3206 40.7479 31.4614 40.355C31.6375 40.0047
        31.6908 39.6054 31.6128 39.2211C31.5348 38.8369 31.3301 38.49 31.0314
        38.236C30.3199 37.5504 29.4664 37.0295 28.5314 36.71C28.0849 36.5403
        27.594 36.5279 27.1395 36.6748C26.685 36.8217 26.2941 37.1191 26.0314
        37.518C25.8021 37.8103 25.6807 38.1728 25.6876 38.5443C25.6945 38.9157
        25.8294 39.2735 26.0694 39.557C26.9578 40.5973 28.17 41.3087 29.5114
        41.577V41.577Z"
        fill="#E7D230"
      />
      <path
        d="M22.3794 45.595C24.2254 44.727 24.7204 43.3 23.5414 42.172C22.7519
        41.426 21.7937 40.8822 20.7484 40.587C20.3057 40.4623 19.8333 40.4965
        19.4131 40.6838C18.993 40.871 18.6517 41.1994 18.4484 41.612C18.222
        41.9658 18.1391 42.3928 18.2165 42.8057C18.294 43.2185 18.5261 43.5863
        18.8654 43.834C19.8651 44.7144 21.0757 45.3211 22.3794 45.595V45.595Z"
        fill="#E7D230"
      />
      <path
        d="M11.9104 35.349C10.8814 35.522 10.0334 35.81 9.72742 36.827C9.58659
        37.1963 9.56892 37.6011 9.67705 37.9812C9.78518 38.3613 10.0133 38.6962
        10.3274 38.936C11.0872 39.5018 11.9092 39.9789 12.7774 40.358C13.0192
        40.4591 13.2792 40.5096 13.5412 40.5061C13.8033 40.5026 14.0619 40.4454
        14.3009 40.3378C14.5399 40.2303 14.7543 40.0748 14.9307 39.8809C15.1071
        39.6871 15.2418 39.4591 15.3264 39.211C15.5186 38.7955 15.5427 38.3218
        15.3936 37.889C15.2444 37.4562 14.9337 37.0979 14.5264 36.889C13.6874
        36.363 12.8204 35.883 11.9104 35.349Z"
        fill="#E7D230"
      />
      <path
        d="M28.8714 26.684C30.6874 25.774 31.2014 24.525 30.2134 23.417C29.3991
        22.5131 28.3189 21.8909 27.1284 21.64C26.7001 21.5629 26.2583 21.6356
        25.8773 21.846C25.4963 22.0563 25.1993 22.3914 25.0364 22.795C24.8723
        23.1929 24.838 23.6325 24.9383 24.0511C25.0387 24.4696 25.2687 24.8458
        25.5954 25.126C26.5569 25.8842 27.6765 26.4166 28.8714 26.684Z"
        fill="#E7D230"
      />
      <path
        d="M37.7314 4.534C38.2371 8.14418 37.8042 11.8238 36.4744 15.218C38.9504
        15.862 41.4354 16.218 43.1744 18.504C40.8264 19.004 38.6294 19.54
        36.8614 21.179C33.7654 17.1441 29.2108 14.4837 24.1754 13.769C24.3632
        11.4123 23.9464 9.04661 22.9644 6.896C25.9564 7.178 27.3924 9.21402
        29.1644 10.973C31.4289 8.13598 34.3766 5.92038 37.7314 4.534V4.534Z"
        fill="#92BC30"
      />
      <path
        d="M9.33241 15.653C7.79593 15.6598 6.27869 15.311 4.89942
        14.6339C4.39241 14.383 3.97385 13.9837 3.69942 13.489C3.43416 13.1062
        3.32044 12.6385 3.3803 12.1766C3.44016 11.7147 3.66933 11.2915 4.02341
        10.989C4.17852 10.8165 4.36623 10.6763 4.57573 10.5766C4.78523 10.477
        5.01239 10.4197 5.24411 10.4082C5.47583 10.3967 5.70753 10.431 5.92589
        10.5094C6.14425 10.5878 6.34495 10.7087 6.51642 10.865C8.10442 11.981
        8.74241 13.665 9.33241 15.653Z"
        fill="#DFCE31"
      />
      <path
        d="M15.8054 10.388C14.4784 8.898 13.3584 7.50402 13.3904 5.52802C13.3621
        5.2602 13.3894 4.98937 13.4706 4.7326C13.5519 4.47584 13.6853 4.23868
        13.8626 4.03595C14.0399 3.83322 14.2572 3.66928 14.5008 3.5545C14.7445
        3.43973 15.0092 3.37662 15.2784 3.36902C15.8176 3.38037 16.3318 3.59872
        16.7144 3.97888C17.097 4.35904 17.3186 4.87187 17.3334 5.41101C17.4531
        7.20142 16.9091 8.97318 15.8054 10.388V10.388Z"
        fill="#DFCE31"
      />
      <path
        d="M14.9834 29.08C15.8524 29.067 16.5514 28.788 17.2964 28.838C17.6886
        28.8202 18.0736 28.9472 18.3782 29.1948C18.6828 29.4425 18.8858 29.7934
        18.9484 30.181C19.1904 31.337 18.9274 32.194 18.0484 32.496C16.6659
        32.9815 15.2511 33.3699 13.8144 33.658C12.7144 33.874 11.9064 33.102
        11.3814 31.603C10.6814 29.609 10.7684 28.803 11.8404 28.116C12.1582
        27.8855 12.5439 27.768 12.9362 27.7819C13.3284 27.7959 13.7048 27.9406
        14.0054 28.193C14.3474 28.454 14.6444 28.767 14.9834 29.08Z"
        fill="#EAAE26"
      />
      <path
        d="M28.0714 31.109C28.0737 30.7891 28.1525 30.4744 28.3012
        30.1911C28.4499 29.9079 28.6641 29.6643 28.9261 29.4807C29.1881 29.2971
        29.4902 29.1788 29.8072 29.1357C30.1242 29.0926 30.4469 29.126 30.7484
        29.233C31.5915 29.5541 32.39 29.9819 33.1244 30.506C33.5629 30.811
        33.8624 31.2777 33.9568 31.8035C34.0512 32.3293 33.9329 32.871 33.6279
        33.3095C33.4769 33.5267 33.2846 33.7119 33.062 33.8548C32.8393 33.9976
        32.5908 34.0951 32.3304 34.1419C31.8047 34.2363 31.263 34.118 30.8244
        33.813C30.1954 33.44 29.5694 33.06 28.9594 32.658C28.6958 32.4924 28.477
        32.2645 28.3221 31.9943C28.1673 31.7242 28.0812 31.4202 28.0714 31.109Z"
        fill="#EAAE26"
      />
      <path
        d="M29.5114 41.577C28.1675 41.3102 26.9526 40.5986 26.0624
        39.557C25.8224 39.2735 25.6875 38.9157 25.6806 38.5443C25.6737 38.1728
        25.7951 37.8103 26.0244 37.518C26.2871 37.1191 26.678 36.8217 27.1325
        36.6748C27.5869 36.5279 28.0779 36.5403 28.5244 36.71C29.4594 37.0295
        30.3129 37.5504 31.0244 38.236C31.3231 38.49 31.5279 38.8369 31.6058
        39.2211C31.6838 39.6054 31.6305 40.0047 31.4544 40.355C31.3139 40.7467
        31.0461 41.0799 30.6939 41.3015C30.3417 41.523 29.9253 41.62 29.5114
        41.577V41.577Z"
        fill="#EAAE26"
      />
      <path
        d="M22.3794 45.595C21.0768 45.3206 19.8673 44.714 18.8684 43.834C18.5291
        43.5863 18.297 43.2185 18.2196 42.8057C18.1421 42.3928 18.225 41.9658
        18.4514 41.612C18.6547 41.1994 18.996 40.871 19.4161 40.6838C19.8363
        40.4965 20.3087 40.4623 20.7514 40.587C21.7966 40.8822 22.7549 41.426
        23.5444 42.172C24.7204 43.3 24.2254 44.727 22.3794 45.595Z"
        fill="#EAAE26"
      />
      <path
        d="M11.9104 35.349C12.8204 35.883 13.6874 36.363 14.5244 36.889C14.9317
        37.0979 15.2424 37.4562 15.3916 37.889C15.5407 38.3218 15.5166 38.7955
        15.3244 39.211C15.2398 39.4591 15.1051 39.6871 14.9287 39.8809C14.7523
        40.0748 14.5379 40.2303 14.2989 40.3378C14.0599 40.4454 13.8013 40.5026
        13.5392 40.5061C13.2772 40.5096 13.0172 40.4591 12.7754 40.358C11.9072
        39.9789 11.0852 39.5018 10.3254 38.936C10.0113 38.6962 9.78318 38.3613
        9.67505 37.9812C9.56692 37.6011 9.58459 37.1963 9.72542 36.827C10.0334
        35.81 10.8814 35.522 11.9104 35.349Z"
        fill="#EAAE26"
      />
      <path
        d="M28.8714 26.684C27.6779 26.4161 26.5598 25.8836 25.5994
        25.126C25.2727 24.8458 25.0427 24.4696 24.9423 24.0511C24.842 23.6325
        24.8763 23.1929 25.0404 22.795C25.2033 22.3914 25.5003 22.0563 25.8813
        21.846C26.2623 21.6356 26.7041 21.5629 27.1324 21.64C28.3229 21.8909
        29.4031 22.5131 30.2174 23.417C31.1994 24.525 30.6874 25.774 28.8714
        26.684Z"
        fill="#EAAE26"
      />
      <path
        d="M19.8044 15.665C27.4964 15.671 33.2844 20.919 34.4044 28.471C35.4364
        35.393 29.6994 45.912 19.1104 45.777C16.6963 45.7235 14.3332 45.0721
        12.2326 43.8811C10.1321 42.6901 8.35972 40.9966 7.07432 38.9525C5.78892
        36.9084 5.0306 34.5773 4.86726 32.1681C4.70392 29.759 5.14068 27.3469
        6.13841 25.148C8.12441 20.89 11.1114 17.72 15.6924 16.257C17.0174
        15.8187 18.4096 15.6182 19.8044 15.665V15.665Z"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M35.7314 2.534C36.2371 6.14418 35.8042 9.82378 34.4744 13.218C36.9504
        13.862 39.4354 14.218 41.1744 16.504C38.8264 17.004 36.6294 17.54
        34.8614 19.179C31.7654 15.1441 27.2108 12.4837 22.1754 11.769C22.3632
        9.41226 21.9464 7.04661 20.9644 4.896C23.9564 5.178 25.3924 7.21402
        27.1644 8.97302C29.4289 6.13598 32.3766 3.92038 35.7314 2.534V2.534Z"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.96442 13.285C5.42896 13.289 3.91329 12.9386 2.53542 12.261C2.02794
        12.0108 1.60917 11.6112 1.33541 11.116C1.06982 10.7333 0.955813 10.2657
        1.01549 9.80378C1.07518 9.34185 1.30431 8.91854 1.65842 8.61598C1.81347
        8.44338 2.00111 8.30321 2.21058 8.20344C2.42004 8.10367 2.64718 8.0463
        2.8789 8.03468C3.11062 8.02306 3.34236 8.05738 3.56075 8.13569C3.77915
        8.21401 3.97989 8.33475 4.15142 8.49098C5.73542 9.61198 6.37442 11.297
        6.96442 13.285Z"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.4374 8.01898C12.1094 6.52998 10.9894 5.135 11.0224 3.159C10.9941
        2.89118 11.0214 2.62041 11.1026 2.36365C11.1839 2.10688 11.3173 1.86966
        11.4946 1.66693C11.6719 1.4642 11.8892 1.30032 12.1328 1.18555C12.3765
        1.07078 12.6412 1.0076 12.9104 1C13.4496 1.01162 13.9637 1.23025 14.3461
        1.6106C14.7285 1.99094 14.9499 2.50382 14.9644 3.04297C15.0852 4.83304
        14.5415 6.60477 13.4374 8.01898Z"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  {:else if name === 'orange'}
    <svg
      width="43"
      height="51"
      viewBox="0 0 43 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.95 14.3267C11.932 14.3267 3 22.3667 3 32.2847C3 42.2027 11.932
        50.2437 22.95 50.2437C33.968 50.2437 42.9 42.2037 42.9 32.2847C42.9
        22.3657 33.968 14.3267 22.95 14.3267ZM22.875 43.9537C15.343 43.9537
        9.237 38.7297 9.237 32.2847C9.237 25.8397 15.343 20.6167 22.875
        20.6167C30.407 20.6167 36.513 25.8407 36.513 32.2847C36.513 38.7287
        30.407 43.9577 22.875 43.9577V43.9537Z"
        fill="#FF9861"
      />
      <path
        d="M27.3263 4.58844L17.2475 6.68226C15.6832 7.00725 14.6785 8.53887
        15.0035 10.1032C15.3284 11.6676 16.8601 12.6723 18.4244 12.3473L28.5032
        10.2535C30.0676 9.92849 31.0723 8.39688 30.7473 6.83252C30.4223 5.26816
        28.8907 4.26345 27.3263 4.58844Z"
        fill="#FF9861"
      />
      <path
        d="M20.95 12.3267C9.93201 12.3267 1 20.3667 1 30.2847C1 40.2027 9.93201
        48.2437 20.95 48.2437C31.968 48.2437 40.9 40.2037 40.9 30.2847C40.9
        20.3657 31.968 12.3267 20.95 12.3267ZM20.875 41.9537C13.343 41.9537
        7.237 36.7297 7.237 30.2847C7.237 23.8397 13.343 18.6167 20.875
        18.6167C28.407 18.6167 34.513 23.8407 34.513 30.2847C34.513 36.7287
        28.407 41.9577 20.875 41.9577V41.9537Z"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.3263 2.58844L15.2475 4.68226C13.6832 5.00725 12.6785 6.53887
        13.0035 8.10323C13.3284 9.66759 14.8601 10.6723 16.4244 10.3473L26.5032
        8.25348C28.0676 7.92849 29.0723 6.39688 28.7473 4.83252C28.4223 3.26816
        26.8907 2.26345 25.3263 2.58844Z"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  {:else if name === 'comeet'}
    <svg
      width="43"
      height="48"
      viewBox="0 0 43 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.6854 13.5877C32.5584 14.2877 32.8534 15.4037 31.6854
        16.7437C30.6932 17.7491 29.4139 18.4224 28.0234 18.6707C24.7144 19.5797
        23.3993 18.4068 21.1843 18.8078C18.9639 19.2655 17.0058 20.5626 15.7182
        22.4286C14.4306 24.2945 13.9128 26.5855 14.2728 28.8238C14.6328 31.0622
        15.8428 33.0753 17.6506 34.4435C19.4583 35.8116 21.7244 36.4293 23.9764
        36.1678C25.9056 35.9595 27.7231 35.1593 29.1794 33.877C30.6358 32.5947
        31.6596 30.8931 32.1104 29.0058C32.6299 27.7689 33.5964 26.7733 34.8173
        26.2173C36.0382 25.6612 37.4237 25.5856 38.6978 26.0056C39.972 26.4255
        41.041 27.3102 41.692 28.4832C42.343 29.6562 42.5281 31.0314 42.2103
        32.3347C39.5643 41.2347 31.8184 47.3687 23.3644 47.5797C12.4844 47.8507
        2.37335 38.2687 3.03035 26.5797C3.22917 23.8207 3.99079 21.1316 5.26829
        18.6781C6.54578 16.2246 8.3121 14.0586 10.4584 12.3136C12.6048 10.5686
        15.0857 9.2816 17.7483 8.53177C20.4109 7.78195 23.1988 7.58526 25.9404
        7.95377C26.9642 8.20054 28.0213 8.2792 29.0703 8.18674C29.7672 8.20374
        30.4501 7.99038 31.0134 7.57974C31.4353 7.14951 31.7114 6.59747 31.8024
        6.00174C32.3024 4.29074 34.8944 2.77474 37.2654 3.02774C38.5626 3.18318
        39.7621 3.79534 40.6491 4.75455C41.5361 5.71376 42.0527 6.95735 42.1064
        8.26273C42.0864 11.0627 39.4673 13.1007 37.4173 13.4527C35.7633 13.7357
        34.8674 12.8577 33.6854 13.5877Z"
        fill="#D367CB"
      />
      <path
        d="M31.6854 11.5877C30.5584 12.2877 30.8534 13.4037 29.6854
        14.7437C28.6932 15.7491 27.4139 16.4224 26.0234 16.6707C22.7144 17.5797
        21.3993 16.4068 19.1843 16.8078C16.9639 17.2655 15.0058 18.5626 13.7182
        20.4286C12.4306 22.2945 11.9128 24.5855 12.2728 26.8238C12.6328 29.0622
        13.8428 31.0753 15.6506 32.4435C17.4583 33.8116 19.7244 34.4293 21.9764
        34.1678C23.9056 33.9595 25.7231 33.1593 27.1794 31.877C28.6358 30.5947
        29.6596 28.8931 30.1104 27.0058C30.6299 25.7689 31.5964 24.7733 32.8173
        24.2173C34.0382 23.6612 35.4237 23.5856 36.6978 24.0056C37.972 24.4255
        39.041 25.3102 39.692 26.4832C40.343 27.6562 40.5281 29.0314 40.2103
        30.3347C37.5643 39.2347 29.8184 45.3687 21.3644 45.5797C10.4844 45.8507
        0.373355 36.2687 1.03035 24.5797C1.22917 21.8207 1.99079 19.1316 3.26829
        16.6781C4.54578 14.2246 6.3121 12.0586 8.45844 10.3136C10.6048 8.56864
        13.0857 7.2816 15.7483 6.53177C18.4109 5.78195 21.1988 5.58526 23.9404
        5.95377C24.9642 6.20054 26.0213 6.2792 27.0703 6.18674C27.7672 6.20374
        28.4501 5.99038 29.0134 5.57974C29.4353 5.14951 29.7114 4.59747 29.8024
        4.00174C30.3024 2.29074 32.8944 0.774743 35.2654 1.02774C36.5626 1.18318
        37.7621 1.79534 38.6491 2.75455C39.5361 3.71376 40.0527 4.95735 40.1064
        6.26273C40.0864 9.06273 37.4673 11.1007 35.4173 11.4527C33.7633 11.7357
        32.8674 10.8577 31.6854 11.5877Z"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  {:else if name === 'universe'}
    <svg
      width="49"
      height="45"
      viewBox="0 0 49 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.6111 25.4462C46.3494 15.0785 38.5432 6.07519 28.1754
        5.33686C17.8076 4.59853 8.80437 12.4047 8.06605 22.7725C7.32772 33.1403
        15.1339 42.1436 25.5017 42.8819C35.8695 43.6202 44.8727 35.814 45.6111
        25.4462Z"
        fill="#56BDD8"
      />
      <path
        d="M43.6111 23.4462C44.3494 13.0785 36.5432 4.0752 26.1754
        3.33688C15.8076 2.59855 6.80437 10.4048 6.06605 20.7725C5.32772 31.1403
        13.1339 40.1436 23.5017 40.8819C33.8695 41.6202 42.8727 33.814 43.6111
        23.4462Z"
        stroke="#171C61"
        stroke-width="1.99905"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.3792 7.21805C44.6102 3.04605 47.0042 2.999 47.6172 3.793C49.3172
        6.004 39.1692 17.269 29.2172 25.256C17.7922 34.427 3.03619 42.164
        1.19619 39.82C0.529189 38.971 1.31417 36.4871 7.48717 29.4131"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  {:else if name === 'jellow'}
    <svg
      width="46"
      height="44"
      viewBox="0 0 46 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9381 5.3103L31.2961 12.1103C31.5926 12.7113 32.0308 13.2311
        32.573 13.625C33.1151 14.0189 33.7449 14.275 34.4081 14.3713L41.9171
        15.4623C42.6804 15.5733 43.3974 15.8956 43.9871 16.3928C44.5768 16.89
        45.0156 17.5423 45.2539 18.2758C45.4923 19.0094 45.5206 19.795 45.3358
        20.5438C45.151 21.2927 44.7604 21.9749 44.2081 22.5133L38.7741
        27.8133C38.2945 28.2813 37.9356 28.8589 37.7285 29.4962C37.5214 30.1336
        37.4722 30.8117 37.5851 31.4723L38.8681 38.9513C38.999 39.7118 38.9142
        40.4938 38.6236 41.2087C38.3329 41.9235 37.8479 42.5427 37.2234
        42.9962C36.599 43.4496 35.8601 43.7192 35.0904 43.7744C34.3206 43.8295
        33.5508 43.6681 32.8681 43.3083L26.1511 39.7773C25.5579 39.4655 24.8978
        39.3026 24.2276 39.3026C23.5575 39.3026 22.8973 39.4655 22.3041
        39.7773L15.5881 43.3083C14.9054 43.6681 14.1356 43.8295 13.3659
        43.7744C12.5962 43.7192 11.8573 43.4496 11.2328 42.9962C10.6084 42.5427
        10.1234 41.9235 9.8327 41.2087C9.54203 40.4938 9.45732 39.7118 9.58813
        38.9513L10.8701 31.4723C10.9835 30.8118 10.9346 30.1336 10.7277
        29.4962C10.5207 28.8587 10.1619 28.2812 9.68213 27.8133L4.24812
        22.5133C3.69585 21.9749 3.30523 21.2927 3.12042 20.5438C2.93561 19.795
        2.96397 19.0094 3.20232 18.2758C3.44066 17.5423 3.87949 16.89 4.46916
        16.3928C5.05884 15.8956 5.77584 15.5733 6.53912 15.4623L14.0481
        14.3713C14.7113 14.275 15.3411 14.0189 15.8833 13.625C16.4254 13.2311
        16.8636 12.7113 17.1601 12.1103L20.5181 5.3103C20.8589 4.61704 21.3871
        4.0331 22.0429 3.62476C22.6986 3.21641 23.4556 3 24.2281 3C25.0006 3
        25.7577 3.21641 26.4134 3.62476C27.0691 4.0331 27.5973 4.61704 27.9381
        5.3103V5.3103Z"
        fill="#FFD91F"
      />
      <path
        d="M25.9381 3.3103L29.2961 10.1103C29.5926 10.7113 30.0308 11.2311
        30.573 11.625C31.1151 12.0189 31.745 12.275 32.4081 12.3713L39.9171
        13.4623C40.6804 13.5733 41.3974 13.8956 41.9871 14.3928C42.5768 14.89
        43.0156 15.5423 43.2539 16.2758C43.4923 17.0094 43.5207 17.795 43.3358
        18.5438C43.151 19.2927 42.7604 19.9749 42.2081 20.5133L36.7741
        25.8133C36.2945 26.2813 35.9357 26.8589 35.7285 27.4962C35.5214 28.1336
        35.4722 28.8117 35.5851 29.4723L36.8681 36.9513C36.999 37.7118 36.9142
        38.4938 36.6236 39.2087C36.3329 39.9235 35.8479 40.5427 35.2234
        40.9962C34.599 41.4496 33.8601 41.7192 33.0904 41.7744C32.3206 41.8295
        31.5509 41.6681 30.8681 41.3083L24.1511 37.7773C23.5579 37.4655 22.8978
        37.3026 22.2276 37.3026C21.5575 37.3026 20.8973 37.4655 20.3041
        37.7773L13.5881 41.3083C12.9054 41.6681 12.1356 41.8295 11.3659
        41.7744C10.5962 41.7192 9.85726 41.4496 9.23283 40.9962C8.6084 40.5427
        8.12338 39.9235 7.83271 39.2087C7.54204 38.4938 7.45732 37.7118 7.58814
        36.9513L8.87014 29.4723C8.98354 28.8118 8.93464 28.1336 8.72769
        27.4962C8.52073 26.8587 8.16191 26.2812 7.68214 25.8133L2.24813
        20.5133C1.69586 19.9749 1.30524 19.2927 1.12042 18.5438C0.935612 17.795
        0.963986 17.0094 1.20233 16.2758C1.44068 15.5423 1.8795 14.89 2.46917
        14.3928C3.05884 13.8956 3.77585 13.5733 4.53913 13.4623L12.0481
        12.3713C12.7113 12.275 13.3411 12.0189 13.8833 11.625C14.4254 11.2311
        14.8636 10.7113 15.1601 10.1103L18.5181 3.3103C18.8589 2.61704 19.3871
        2.0331 20.0429 1.62476C20.6986 1.21641 21.4557 1 22.2281 1C23.0006 1
        23.7577 1.21641 24.4134 1.62476C25.0691 2.0331 25.5974 2.61704 25.9381
        3.3103V3.3103Z"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  {:else if name === 'jike'}
    <svg
      width="27"
      height="52"
      viewBox="0 0 27 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.806 2.99998H26.306L26.375 30.339C26.0699 33.1832 25.2944 35.957
        24.08 38.547C21.2488 44.2851 16.5332 48.8771 10.722 51.555L3
        40.634C5.97772 39.3833 8.53612 37.3078 10.374 34.652C11.3712 33.1832
        12.123 31.562 12.6 29.852C12.668 20.9006 12.7367 11.95 12.806 2.99998Z"
        fill="#FFDD11"
      />
      <path
        d="M10.806 1H24.306L24.375 28.339C24.0699 31.1832 23.2944 33.957 22.08
        36.547C19.2488 42.2851 14.5332 46.8771 8.722 49.555L1 38.634C3.97772
        37.3833 6.53612 35.3079 8.374 32.652C9.37124 31.1833 10.123 29.5621 10.6
        27.852C10.668 18.9007 10.7367 9.95 10.806 1Z"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.46899 46.373C8.47612 45.5432 10.3573 44.4369 12.058 43.086C14.5235
        41.1344 16.5599 38.6953 18.04 35.921C19.5669 33.1453 20.4082 30.0448
        20.494 26.878L20.545 1"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  {:else if name === 'kuai'}
    <svg
      width="56"
      height="28"
      viewBox="0 0 56 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.599 27.296V13.638L5.49298
        13.429V25.763H2V3.499H5.49298V9.875L6.599 10.084V2H10.324V10.887L11.633
        11.166V14.72L10.324 14.441V27.298L6.599 27.296ZM20.481 18.377L19.957
        19.492H23.357L27.548 27.227H23.328L19.574 20.327L16.352
        27.296H11.899L16.294
        18.377H12.307V14.997H16.76V7.645H12.627V4.196H16.76V1.99599H20.485V4.196H23.885C24.3199
        4.19472 24.7489 4.29758 25.136 4.49599C25.528 4.6962 25.8748 4.97459
        26.155 5.314C26.4537 5.67678 26.6853 6.08991 26.839 6.534C27.0042
        7.00401 27.0877 7.49882 27.086 7.997V14.997H27.552V18.377H20.481ZM20.481
        14.997H23.042V8.725C23.05 8.4464 22.957 8.17432 22.78 7.959C22.7049
        7.86075 22.6079 7.78125 22.4969 7.72677C22.3858 7.67229 22.2637 7.6443
        22.14 7.645H20.481V14.997Z"
        fill="white"
      />
      <path
        d="M48.099 23.568H30.143V20.087H48.099V23.568ZM51.068 23.777C51.2298
        23.7748 51.3851 23.7128 51.504 23.603C51.6106 23.4487 51.6713 23.2674
        51.679 23.08V18.133H30.87V3.603H33.344L34.13 2.035H37.768L36.982
        3.603H53.891V11.203C53.9272 11.8894 53.6976 12.5634 53.25 13.085C53.0719
        13.3256 52.8393 13.5205 52.5712 13.6537C52.3032 13.7868 52.0073 13.8545
        51.708 13.851H47.517L48.856 10.436H49.671C49.7525 10.4279 49.8312
        10.4019 49.9016 10.3599C49.9719 10.3179 50.0322 10.2609 50.078
        10.193C50.1423 10.1303 50.1915 10.0538 50.2219 9.96921C50.2522 9.88465
        50.2628 9.79429 50.253 9.70499V7.087H34.508V14.687H55.288V24.618C55.316
        25.3029 55.0874 25.9737 54.647 26.499C54.4725 26.7269 54.2479 26.9114
        53.9905 27.0384C53.7331 27.1653 53.45 27.2312 53.163
        27.231H47.372L48.681 23.781L51.068 23.777ZM37.535 9.039H41.697L45.451
        13.739H41.26L37.535 9.039Z"
        fill="white"
      />
      <path
        d="M4.599 25.296V11.638L3.49298
        11.429V23.763H0V1.499H3.49298V7.875L4.599 8.084V0H8.32397V8.887L9.633
        9.166V12.72L8.32397 12.441V25.298L4.599 25.296ZM18.481 16.377L17.957
        17.492H21.357L25.548 25.227H21.328L17.574 18.327L14.352
        25.296H9.89899L14.294
        16.377H10.307V12.997H14.76V5.645H10.627V2.196H14.76V-0.00400543H18.485V2.196H21.885C22.3199
        2.19472 22.7489 2.29758 23.136 2.49599C23.528 2.6962 23.8748 2.97459
        24.155 3.314C24.4537 3.67678 24.6853 4.08991 24.839 4.534C25.0042
        5.00401 25.0877 5.49882 25.086 5.997V12.997H25.552V16.377H18.481ZM18.481
        12.997H21.042V6.725C21.05 6.4464 20.957 6.17432 20.78 5.959C20.7049
        5.86075 20.6079 5.78125 20.4969 5.72677C20.3858 5.67229 20.2637 5.6443
        20.14 5.645H18.481V12.997Z"
        fill="#171C61"
      />
      <path
        d="M46.099 21.568H28.143V18.087H46.099V21.568ZM49.068 21.777C49.2298
        21.7748 49.3851 21.7128 49.504 21.603C49.6106 21.4487 49.6713 21.2674
        49.679 21.08V16.133H28.87V1.603H31.344L32.13 0.034996H35.768L34.982
        1.603H51.891V9.203C51.9272 9.8894 51.6976 10.5634 51.25 11.085C51.0719
        11.3256 50.8393 11.5205 50.5712 11.6537C50.3032 11.7868 50.0073 11.8545
        49.708 11.851H45.517L46.856 8.436H47.671C47.7525 8.42785 47.8312 8.40186
        47.9016 8.35986C47.9719 8.31787 48.0322 8.2609 48.078 8.193C48.1423
        8.13031 48.1915 8.05377 48.2219 7.96921C48.2522 7.88465 48.2628 7.79429
        48.253 7.70499V5.087H32.508V12.687H53.288V22.618C53.316 23.3029 53.0874
        23.9737 52.647 24.499C52.4725 24.7269 52.2479 24.9114 51.9905
        25.0384C51.7331 25.1653 51.45 25.2312 51.163 25.231H45.372L46.681
        21.781L49.068 21.777ZM35.535 7.039H39.697L43.451 11.739H39.26L35.535
        7.039Z"
        fill="#171C61"
      />
    </svg>
  {:else if name === 'yiguan'}
    <svg
      width="40"
      height="48"
      viewBox="0 0 40 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.91733 16.045L4.62734 18.288C3.54605 20.1685 2.9845 22.3028 3.00033
        24.472L3.07033 34.358C3.07768 36.0576 3.50302 37.7293 4.30887
        39.2258C5.11473 40.7222 6.27636 41.9974 7.69134 42.939C11.6293 45.5823
        16.2595 47.0054 21.0023 47.03C26.9209 46.9918 32.6285 44.8268 37.0833
        40.93C37.5387 40.5324 37.908 40.046 38.1685 39.5005C38.429 38.955
        38.5753 38.3621 38.5983 37.758C38.7643 33.7687 38.931 29.7797 39.0983
        25.791C39.2763 23.1798 38.7034 20.5722 37.4473 18.276C37.1133 17.6055
        36.7299 16.9607 36.3003 16.347"
        fill="#1ABEFA"
      />
      <path
        d="M20.8713 13.998C30.401 13.998 38.1263 11.536 38.1263 8.499C38.1263
        5.46199 30.401 3 20.8713 3C11.3417 3 3.61633 5.46199 3.61633
        8.499C3.61633 11.536 11.3417 13.998 20.8713 13.998Z"
        fill="#1ABEFA"
      />
      <path
        d="M3.91733 14.045L2.62734 16.288C1.54605 18.1685 0.984498 20.3028
        1.00033 22.472L1.07033 32.358C1.07768 34.0576 1.50302 35.7293 2.30887
        37.2258C3.11473 38.7222 4.27636 39.9974 5.69134 40.939C9.62931 43.5823
        14.2595 45.0054 19.0023 45.03C24.9209 44.9918 30.6285 42.8268 35.0833
        38.93C35.5387 38.5324 35.908 38.046 36.1685 37.5005C36.429 36.955
        36.5753 36.3621 36.5983 35.758C36.7643 31.7687 36.931 27.7797 37.0983
        23.791C37.2763 21.1798 36.7034 18.5722 35.4473 16.276C35.1133 15.6055
        34.7299 14.9607 34.3003 14.347"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.8713 11.998C28.401 11.998 36.1263 9.53601 36.1263 6.499C36.1263
        3.46199 28.401 1 18.8713 1C9.34165 1 1.61633 3.46199 1.61633
        6.499C1.61633 9.53601 9.34165 11.998 18.8713 11.998Z"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.1063 6.253C36.0743 8.653 36.041 11.053 36.0063 13.453C30.6633
        16.1107 24.7652 17.4603 18.7981 17.3908C12.831 17.3212 6.96593 15.8344
        1.68633 13.053L1.61032 6.50599"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.26132 21.418C14.3825 24.442 23.3025 24.5481 31.4933 21.718"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  {:else if name === 'switch'}
    <svg
      width="31"
      height="29"
      viewBox="0 0 31 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 3V0H12H9C6.5292 0 4.16759 0.690273 2.42893 2.42893C0.690273
        4.16759 0 6.5292 0 9V20C0 22.4708 0.690273 24.8324 2.42893
        26.5711C4.16759 28.3097 6.5292 29 9 29H12H15V26V3ZM3 9C3 5.13401 5.13401
        3 9 3H12V6V23V26H9C5.13401 26 3 23.866 3 20V9ZM7.5 12C8.88071 12 10
        10.8807 10 9.5C10 8.11929 8.88071 7 7.5 7C6.11929 7 5 8.11929 5 9.5C5
        10.8807 6.11929 12 7.5 12Z"
        fill="#F05050"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17 0L17 29H22.6C24.9061 29 27.1102 28.3097 28.733 26.5711C30.3557
        24.8324 31 22.4708 31 20L31 9C31 6.5292 30.3557 4.16759 28.733
        2.42893C27.1103 0.690274 24.9061 0 22.6 0H17ZM23.5 13C22.1193 13 21
        14.1193 21 15.5C21 16.8807 22.1193 18 23.5 18C24.8807 18 26 16.8807 26
        15.5C26 14.1193 24.8807 13 23.5 13Z"
        fill="#F05050"
      />
    </svg>
  {:else if name === 'custom'}
    <svg
      width="74"
      height="73"
      viewBox="0 0 74 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40" cy="39" r="31" stroke="black" stroke-width="6" />
      <circle cx="34" cy="34" r="31" stroke="white" stroke-width="6" />
    </svg>
  {:else if name === 'shengchen'}
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1325 42.369C32.7519 42.369 41.3605 33.7361 41.3605 23.087C41.3605
        12.4378 32.7519 3.80499 22.1325 3.80499C11.5132 3.80499 2.90454 12.4378
        2.90454 23.087C2.90454 33.7361 11.5132 42.369 22.1325 42.369Z"
        fill="#D5D4D5"
      />
      <path
        d="M20.6085 39.564C20.4985 39.564 20.3875 39.564 20.2755 39.564C16.6951
        39.5628 13.1857 38.5648 10.1405 36.6816C7.09528 34.7984 4.63456 32.1046
        3.03397 28.9018C1.43337 25.6991 0.756144 22.1139 1.07814 18.5479C1.40014
        14.982 2.70864 11.5762 4.85707 8.71194C7.00551 5.8477 9.909 3.63821
        13.2423 2.33101C16.5756 1.02382 20.207 0.670544 23.7298 1.31075C27.2525
        1.95096 30.5274 3.55941 33.1876 5.95583C35.8478 8.35226 37.7883 11.442
        38.7915 14.879"
        stroke="#171C61"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39.4345 18.087C39.7383 20.7587 39.4804 23.4644 38.6774
        26.0307C37.8744 28.597 36.544 30.9671 34.7715 32.9892C32.9991 35.0113
        30.8236 36.6407 28.3846 37.7729C25.9457 38.9051 23.297 39.5152 20.6085
        39.564"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  {:else if name === 'cross'}
    <svg {fill} viewBox="0 0 24 24" {stroke} {width} {height} class="w-8 h-8">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  {:else if name === 'trash'}
    <svg
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      viewBox="0 0 24 24"
      class="w-8 h-8"
    >
      <path
        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5
        4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
      />
    </svg>
  {/if}
</div>

<style lang="scss">
  .icon {
    & > svg {
      width: 100%;
      height: 100%;
    }
  }
</style>
