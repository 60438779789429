<script>
  import ICON from "./ICONS.svelte";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  let availableCards = {
    custom: {
      name: "自定义",
      fields: {
        title: "",
        url: "",
        sub: "", // id or nick name
        info: "" //
      },
      hints: {
        title: "卡片名字",
        url: "链接地址",
        sub: "名字",
        info: "介绍语"
      }
    },
    switch: {
      name: "Switch",
      fields: {
        title: "Switch",
        url: "https://toys.gnimoay.com",
        sub: "", // id or nick name
        info: "",
        // css: "background-color:#adb5bb;",
        _opts: { noUrl: true }
      },
      hints: {
        title: "卡片名字",
        url: "",
        sub: "昵称",
        info: "好友编号"
      }
    },
    jstore: {
      name: "即士多",
      fields: {
        title: "即士多",
        url: "",
        sub: "", // id or nick name
        info: "" //
      },
      hints: {
        title: "卡片名字",
        url: "店铺地址",
        sub: "店铺名",
        info: "你的ID"
      }
    },
    pingpal: {
      name: "PingPal",
      fields: {
        title: "PingPal",
        url: "",
        sub: "", // id or nick name
        info: "" //
      },
      hints: {
        title: "卡片名字",
        url: "",
        sub: "昵称",
        info: ""
      }
    },
    orange: {
      name: "橙App",
      fields: {
        title: "橙App",
        url: "",
        sub: "", // id or nick name
        info: "" //
      },
      hints: {
        title: "卡片名字",
        url: "",
        sub: "昵称",
        info: ""
      }
    },
    comeet: {
      name: "Comeet",
      fields: {
        title: "Comeet",
        url: "",
        sub: "", // id or nick name
        info: "" //
      },
      hints: {
        title: "卡片名字",
        url: "",
        sub: "昵称",
        info: ""
      }
    },
    universe: {
      name: "小宇宙",
      fields: {
        title: "小宇宙",
        url: "",
        sub: "", // id or nick name
        info: "" //
      },
      hints: {
        title: "卡片名字",
        url: "节目地址",
        sub: "昵称",
        info: ""
      }
    },
    jellow: {
      name: "Jellow",
      fields: {
        title: "Jellow",
        url: "",
        sub: "", // id or nick name
        info: "" //
      },
      hints: {
        title: "卡片名字",
        url: "",
        sub: "昵称",
        info: ""
      }
    },
    jike: {
      name: "即刻",
      fields: {
        title: "即刻",
        url: "",
        sub: "", // id or nick name
        info: "" //
      },
      hints: {
        title: "卡片名字",
        url: "",
        sub: "昵称",
        info: ""
      }
    },
    kuai: {
      name: "快鸟返利",
      fields: {
        title: "快鸟返利",
        url: "",
        sub: "", // id or nick name
        info: "" //
      },
      hints: {
        title: "卡片名字",
        url: "",
        sub: "邀请码",
        info: "昵称"
      }
    },
    yiguan: {
      name: "一罐",
      fields: {
        title: "一罐",
        url: "",
        sub: "", // id or nick name
        info: "" //
      },
      hints: {
        title: "卡片名字",
        url: "",
        sub: "",
        info: ""
      }
    },
    shengchen: {
      name: "生辰",
      fields: {
        title: "生辰",
        url: "",
        sub: "", // id or nick name
        info: "" //
      },
      hints: {
        title: "卡片名字",
        url: "",
        sub: "",
        info: ""
      }
    }
  };

  let QR;
  let selectedCard = null;

  export async function genCard() {
    // rFields.url.length >0
    //     QR.makeCode(rFields.url)
    // alert(QR)
    if (rFields.url.length > 0) {
      const rst = await QRCode.toDataURL(rFields.url, { margin: 0 });
      rFields.image = rst;
    }
    dispatch("cardReady");
    setTimeout(() => {
      selectedCard = null;
    }, 600);
  }

  export let rFields = undefined;
</script>

<style lang="scss">
  .container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    color: white;
    background: var(--cardCase-bg-sub);
  }
  .cards {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    overflow-x: scroll;
    scrollbar-width: 0;
    scroll-behavior: sn;
  }
  .card {
    border-radius: 5px;
    height: 35%;
    margin: 5% 0 0 5%;
    background: #f8f8f8;
    padding: auto;
    padding: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px 2px;
    width: 25%;
    &.selected {
      background: var(--cardCase-bg-light);
      .name {
        color: white;
      }
    }

    .name {
      // color: white;
      color: black;
      font-weight: bold;

      padding: 0.08rem;
    }
  }
  .form {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    width: 100%;
    justify-content: space-around;
    align-items: center;

    > * {
      width: 40%;
      &:nth-child(n-2) {
        order: 1;
      }
      &:nth-child(n + 3) {
        order: 2;
        margin-top: -12%;
      }
    }
    label {
      font-weight: bold;
      padding: 0 0 6px 0;
    }
    input {
      border-radius: 8px;
      width: 100%;
      &[disabled] {
        border: none;
        background: #666 !important;
        color: transparent;
        opacity: 0.4;
      }
    }
  }
</style>

<svelte:head>
  <title>Cyber Cards</title>
  <script src="https://cdn.jsdelivr.net/npm/qrcode@1.4.4/build/qrcode.min.js">

  </script>
  <!-- <script src="https://cdn.rawgit.com/davidshimjs/qrcodejs/gh-pages/qrcode.min.js" on:load={( )=>{
    QR=new QRCode('qrcode');
    QR._oDrawing._elImage.onload=()=>{
      rFields.image=QR._oDrawing._elImage.src
      dispatch('cardReady')
      setTimeout(()=>{

      selectedCard=null;
      },600)
    }
  }}>
  </script> -->
</svelte:head>
<div class="container">
  <div class="cards">
    {#each Object.keys(availableCards) as k}
      <div
        class={'card ' + k}
        class:selected={selectedCard === k}
        on:click={evt => {
          selectedCard = k;
          rFields = { ...availableCards[selectedCard].fields, type: selectedCard };
          setTimeout(() =>
            document
              .querySelector('.container')
              .scrollBy({ top: 500, left: 0, behavior: 'smooth' })
          );
        }}>
        <i>
          <ICON name={k} />
        </i>
        <div class="name">{availableCards[k].name}</div>
      </div>
    {/each}
  </div>
  {#if selectedCard !== null}
    <div class="form">
      <div>
        <label for="card-name">Card Name:</label>
        <input
          type="text"
          name="card-name"
          bind:value={rFields.title}
          placeholder={availableCards[selectedCard].hints.title} />
      </div>
      <div>
        <label for="title">Title:</label>
        <input
          type="text"
          name="title"
          bind:value={rFields.sub}
          placeholder={availableCards[selectedCard].hints.sub} />
      </div>
      <div>
        <label for="sub-title">Sub Title:</label>
        <input
          type="text"
          name="sub-title"
          bind:value={rFields.info}
          placeholder={availableCards[selectedCard].hints.info} />
      </div>
      <div>
        <label for="url">URL:</label>
        <input
          type="text"
          name="url"
          disabled={rFields._opts && rFields._opts.noUrl}
          bind:value={rFields.url}
          placeholder={availableCards[selectedCard].hints.url} />
      </div>
    </div>
  {/if}

</div>
<div id="qrcode" style="display:none;" />
