<script context="module">
  export function clamp(v, min, max) {
    return v < min ? min : v > max ? max : v
  }

  export const ifiOS =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
  export const ifWX =
    navigator.userAgent.toLowerCase().match(/MicroMessenger/i) ==
    'micromessenger'
  export const isMobile =
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  export const isUrl = url => new RegExp(/^http/).test(url)

  export const bgClrs = {
    jstore: ['#ffdd11'],
    jike: ['#ffdd11'],
    switch: ['#2d2980'],
  }

  export function downloadImgURL(dataURL) {
    var a = document.createElement('a')
    var filename = 'img' + new Date().toDateString() + '.png'
    a.href = dataURL
    a.download = filename
    document.body.appendChild(a)
    a.click()
  }
  export function copy(text) {
    const fake = document.body.appendChild(document.createElement('textArea'))
    fake.style.position = 'absolute'
    fake.style.left = '-9999px'
    fake.setAttribute('readonly', '')
    fake.value = '' + text
    fake.select()
    try {
      return document.execCommand('copy')
    } catch (err) {
      return false
    } finally {
      fake.parentNode.removeChild(fake)
    }
  }

  export function map(v, a, b, A, B) {
    const prog = (v - a) / (b - a)
    return (B - A) * prog + A
  }

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  export function debounce(func, wait, immediate) {
    var timeout
    return function () {
      var context = this,
        args = arguments
      var later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      var callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  }
  // Returns a function, that, when invoked, will only be triggered at most once
  // during a given window of time. Normally, the throttled function will run
  // as much as it can, without ever going more than once per `wait` duration;
  // but if you'd like to disable the execution on the leading edge, pass
  // `{leading: false}`. To disable execution on the trailing edge, ditto.
  export function throttle(func, wait, options) {
    var context, args, result
    var timeout = null
    var previous = 0
    if (!options) options = {}
    var later = function () {
      previous = options.leading === false ? 0 : Date.now()
      timeout = null
      result = func.apply(context, args)
      if (!timeout) context = args = null
    }
    return function () {
      var now = Date.now()
      if (!previous && options.leading === false) previous = now
      var remaining = wait - (now - previous)
      context = this
      args = arguments
      if (remaining <= 0 || remaining > wait) {
        if (timeout) {
          clearTimeout(timeout)
          timeout = null
        }
        previous = now
        result = func.apply(context, args)
        if (!timeout) context = args = null
      } else if (!timeout && options.trailing !== false) {
        timeout = setTimeout(later, remaining)
      }
      return result
    }
  }
  // src
  // https://gist.github.com/gre/1650294
  export const E = {
    // no easing, no acceleration
    linear: function (t) {
      return t
    },
    // accelerating from zero velocity
    easeInQuad: function (t) {
      return t * t
    },
    // decelerating to zero velocity
    easeOutQuad: function (t) {
      return t * (2 - t)
    },
    // acceleration until halfway, then deceleration
    easeInOutQuad: function (t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
    },
    // accelerating from zero velocity
    easeInCubic: function (t) {
      return t * t * t
    },
    // decelerating to zero velocity
    easeOutCubic: function (t) {
      return --t * t * t + 1
    },
    // acceleration until halfway, then deceleration
    easeInOutCubic: function (t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    },
    // accelerating from zero velocity
    easeInQuart: function (t) {
      return t * t * t * t
    },
    // decelerating to zero velocity
    easeOutQuart: function (t) {
      return 1 - --t * t * t * t
    },
    // acceleration until halfway, then deceleration
    easeInOutQuart: function (t) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t
    },
    // accelerating from zero velocity
    easeInQuint: function (t) {
      return t * t * t * t * t
    },
    // decelerating to zero velocity
    easeOutQuint: function (t) {
      return 1 + --t * t * t * t * t
    },
    // acceleration until halfway, then deceleration
    easeInOutQuint: function (t) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
    },

    // elastic bounce effect at the beginning
    easeInElastic: function (t) {
      return (0.04 - 0.04 / t) * Math.sin(25 * t) + 1
    },
    // elastic bounce effect at the end
    easeOutElastic: function (t) {
      return ((0.04 * t) / --t) * Math.sin(25 * t)
    },
    // elastic bounce effect at the beginning and end
    easeInOutElastic: function (t) {
      return (t -= 0.5) < 0
        ? (0.02 + 0.01 / t) * Math.sin(50 * t)
        : (0.02 - 0.01 / t) * Math.sin(50 * t) + 1
    },
  }

  E.easeOutBack = function (t, b, c, d, s) {
    if (s == undefined) s = 1.70158
    return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b
  }

  E.easeOutExpo = function (t, b, c, d) {
    return t == d ? b + c : c * (-Math.pow(2, (-10 * t) / d) + 1) + b
  }

  E.outElastic = function (n) {
    var s,
      a = 0.1,
      p = 0.4
    if (n === 0) return 0
    if (n === 1) return 1
    if (!a || a < 1) {
      a = 1
      s = p / 4
    } else s = (p * Math.asin(1 / a)) / (2 * Math.PI)
    return (
      a * Math.pow(2, -10 * n) * Math.sin(((n - s) * (2 * Math.PI)) / p) + 1
    )
  }
</script>
